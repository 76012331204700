import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";

@inject(DialogController)
export class ConfirmDialog {
  dialogController: DialogController;
  title: string;
  message: string;
  yesButtonText: string;
  noButtonText: string;
  constructor(dialogController: DialogController) {
    this.dialogController = dialogController;
  }

  activate(model: any) {
    this.title = model.title;
    this.message = model.message;
    this.yesButtonText = model.yesButtonText ? model.yesButtonText : "Yes";
    this.noButtonText = model.noButtonText ? model.noButtonText : "No";
  }
}