export class ColorTheme {
  public primaryColor: string;
  public primaryTextColor: string;
  public secondaryColor: string;
  public secondaryTextColor: string;
  public errorColor: string;
  public errorTextColor: string;
  public successColor: string;
  public successTextColor: string;
  public accentColor: string;
  public accentTextColor: string;
  public linkColor: string;
  public linkTextColor: string;
  /*
  constructor(primaryColor: string, secondaryColor: string,
    accentColor: string, errorColor: string,
    successColor: string, linkColor: string,
    primaryTextColor: string, secondaryTextColor: string,
    accentTextColor: string, errorTextColor: string,
    successTextColor: string, linkTextColor: string ) {
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.accentColor = accentColor;
    this.successColor = successColor;
    this.errorColor = errorColor;
    this.linkColor = linkColor;
    // text colors
    this.primaryTextColor = primaryTextColor;
    this.secondaryTextColor = secondaryTextColor;
    this.accentTextColor = accentTextColor;
    this.successTextColor = successTextColor;
    this.errorTextColor = errorTextColor;
    this.linkTextColor = linkTextColor;
  }
*/
  setTheme(primaryColor: string, secondaryColor: string,
    accentColor: string, errorColor: string,
    successColor: string, linkColor: string,
    primaryTextColor: string, secondaryTextColor: string,
    accentTextColor: string, errorTextColor: string,
    successTextColor: string, linkTextColor: string) {
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.accentColor = accentColor;
    this.successColor = successColor;
    this.errorColor = errorColor;
    this.linkColor = linkColor;
    // text colors
    this.primaryTextColor = primaryTextColor;
    this.secondaryTextColor = secondaryTextColor;
    this.accentTextColor = accentTextColor;
    this.successTextColor = successTextColor;
    this.errorTextColor = errorTextColor;
    this.linkTextColor = linkTextColor;
  }
}
