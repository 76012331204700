import { PWAInstallType } from "common/enums";
export class PWAOptions {
  public deferredPrompt: IBeforeInstallPromptEvent;
  public pwaInstallType: PWAInstallType;
  public hasBeforeInstallPrompt: boolean;
  constructor() {
    this.deferredPrompt = undefined;
    this.pwaInstallType = PWAInstallType.None;
    this.hasBeforeInstallPrompt = false;
  }
}