import { inject, Container } from "aurelia-framework";
import { AppSecurity } from "services/appsecurity";
import { CacheKey } from "common/enums";
import { CacheService } from "services/cache-service";

@inject(CacheService)
export class AccessRestraint {
  hasSubscription: boolean;
  isAnonymous: boolean;
  id: number;
  spotLightAccessLimit: number;
  accessCount: number;
  accessDate: Date;
  currentDate: Date;
  cacheService: CacheService;

  constructor(cacheService: CacheService) {
    this.cacheService = cacheService;
    this.accessCount = 0;
    this.isAnonymous = true;
    this.hasSubscription = false;
    this.accessDate = new Date();
    this.accessDate.setHours(0, 0, 0, 0);
  }

  /**
   * Configures the limit access based on the user information.
   */
  private config() {
    const subscriberAccessLimit = 100;
    const freeUserAccessLimit = 14;
    const anonymousAccessLimit = 7;

    let appSecurity = <AppSecurity>Container.instance.get(AppSecurity);
    if (appSecurity.userInfo) {
      this.isAnonymous = false;
      if (appSecurity.userInfo.getUserSubscriptions().hasSubscriptions) {
        this.spotLightAccessLimit = subscriberAccessLimit
        this.hasSubscription = true;
      } else {
        this.spotLightAccessLimit = freeUserAccessLimit
        this.hasSubscription = false;
      }
    } else {
      this.spotLightAccessLimit = anonymousAccessLimit
      this.isAnonymous = true;
    }
  }

  /**
   * Saves this object to local storage
   */
  save() {
    let data = JSON.stringify(this, this.replacer);
    this.cacheService.store(CacheKey.AccessRestraints, data, true);
  }

  /**
   * Retrieve local storage data or save the initial version of this object to local storage.
   */
  retrieve() {
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);
    this.config();
    if (this.cacheService.retrieve(CacheKey.AccessRestraints) == undefined) {
      this.accessDate = this.currentDate;
      this.save();
    } else {
      let data = <AccessRestraint>JSON.parse(this.cacheService.retrieve(CacheKey.AccessRestraints));
//      this.accessCount = data.accessCount;
      this.accessCount = 0 //bem test;
      this.id = data.id;
      this.accessDate = new Date(data.accessDate);
      if (this.accessDate.getTime() != this.currentDate.getTime()) {
        // reset the access date and count.
        this.accessDate = this.currentDate;
        this.accessCount = 0;
      }
    }
  }

  /**
   * Determines if the daily access count has been reached and optionally
   * increments the daily access count.
   * @param incrementAccessCount - defauts to false
   */
  accessLimitReached(incrementAccessCount: boolean = false) {
    if (incrementAccessCount && this.accessCount < this.spotLightAccessLimit) {
      this.accessCount++;
      this.save();
    }
    return this.accessCount >= this.spotLightAccessLimit ? true : false;
  }

  /**
   * JSON stringify replacer method to remove unwanted properties.
   * @param key
   * @param value
   */
  replacer(key, value) {
    if (key == "cacheService") return undefined;
    if (key == "currentDate") return undefined;
    if (key == "spotLightAccessLimit") return undefined;
    if (key == "isAnonymous") return undefined;
    if (key == "hasSubscription") return undefined;
    return value;
  }
}