/**
 * Orignal version created by ericjohnson on 10/6/15.
 * https://gist.githubusercontent.com/singledigit/1fc604942484cec9413c/raw/a005196f9db851d07f274fdc95a19562a2f3703b/Aurelia%2520Storage%2520Class
 * https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html
 * Adapted for Aurelia with TypeScript by Bruce Merritt 4/14/19.
 * Rename class to Cache to avoid name collision with TypeScript library definition.
 * Update to use TypeScript enumeration as key.
 */

import moment from "moment";
import { CacheKey } from "common/enums";

class CacheData {
  stamp: moment.Moment;
  data: any;

  constructor(stamp: moment.Moment, data: any) {
    this.stamp = stamp;
    this.data = data;
  }
}

/**
 * Cache class tht works with local and session storage.
 */
export class CacheService {
  private index: Storage;
  private storage: Storage;
  private session: Storage;

  constructor() {
    this.index = <Storage>{};
    this.storage = window.localStorage;
    this.session = window.sessionStorage;

    // reload index if needed
    this.loadIndex();
  }

  /**
   * loadIndex
   * Load local/session storage key.
   */
  loadIndex() {
    let x = Object.keys(this.storage);

    for (var i = 0; i < x.length; i++) {
      this.index[x[i]] = true;
    }
  }

  /**
   * store key/value
   * param key
   * param expiration - default 21 days
   * param session  - default true
   */
  store(key: CacheKey, value: any,
    persistent: boolean = false, expiration: number = (60 * 60 * 24 * 21)) {
    // expiration default is 21 days multiplied by seconds
    let item = JSON.stringify(new CacheData(moment().add(expiration, "seconds"), value));

    if (persistent) {
      this.index[key] = true;
      this.storage.setItem(key, item);
    }
    else {
      this.session.setItem(key, item);
    }
  }

  /**
   * get key persistent state
   * param key
   */
  isPersistent(key: string) {
    return this.index[key] ? true : false;
  }

  /**
   * retrieve by key stored object, or remove if expired.
   * param key
   */
  retrieve(key: CacheKey) {
    let returnItem = <CacheData>JSON.parse(this[this.index[key] ? "storage" : "session"].getItem(key));

    // if exists and not expired then return value
    if (returnItem && moment() <= moment(returnItem.stamp)) {
      return returnItem.data;
    }

    // else, clear from storage and return null
    this.remove(key);

    return undefined;
  }

  /**
   * remove stored item by key
   * param key
   */
  remove(key: CacheKey) {
    this[this.index[key] ? "storage" : "session"].removeItem(key);
    delete this.index[key];
  }

  /**
   * Archive session storage elements in local storage.
   */
  archiveSessionStorageToLocalStorage(): Object {
    var backup: Object[] = [];

    for (var i = 0; i < this.session.length; i++) {
      backup[i] = JSON.parse(this.session.getItem(sessionStorage.key(i)));
    }

    localStorage.setItem(CacheKey.SessionStorageBackup, JSON.stringify(backup));
    this.session.clear();
    return {};
  };

  /**
  * restore session storage from local
  */
  restoreSessionStorageFromLocalStorage(): void {
    var backupText = this.storage.getItem(CacheKey.SessionStorageBackup),
      backup;

    if (backupText) {
      backup = JSON.parse(backupText);

      for (var key in backup) {
        if (backup.hasOwnProperty(key)) {
          this.session.setItem(key, backup[key]);
        }
      }
      this.storage.removeItem(CacheKey.SessionStorageBackup);
    }
  };
}