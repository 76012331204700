/** 
  * @module Logging errors to the console and showing toasts with Stashy.Notify
  * @requires system
  */

import { inject } from "aurelia-framework";
import { ErrorDisplayType, ToastDisplay, LogDisplay } from "common/enums";
import toastr from "toastr";
//import { MdToastService } from "aurelia-materialize-bridge";
import * as LogManager from "aurelia-logging";

export class Logger {
  public logManager: LogManager.Logger;
  defaultToastOptions: ToastrOptions
  //mdToastService: MdToastService;

  constructor() {
    this.logManager = LogManager.getLogger("spotonlove");
    //this.mdToastService = mdToastService;
    this.defaultToastOptions = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: "toast-top-left",
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    var temp = LogManager.getAppenders();
  }

  /**
   * Helper method to default LogManager method.
   * @param message
   * @param rest
   */
  debug(message:string, ...rest: any[]): void {
    this.logManager.debug(message, rest)
  }

  /**
   * Helper method to default LogManager method.
   * @param message
   * @param rest
   */
  info(message: string, ...rest: any[]): void {
    this.logManager.info(message, rest)
  }

  /**
   * Helper method to default LogManager method.
   * @param message
   * @param rest
   */
  warn(message: string, ...rest: any[]): void {
    this.logManager.warn(message, rest)
  }

  /**
   * Helper method to default LogManager method.
   * @param message
   * @param rest
   */
  error(message: string, ...rest: any[]): void {
    this.logManager.error(message, rest)
  }

  /**
   * Log Info message
   * @method
   * @param {string} message
   * @param {object} data - The data object to log into console
   * @param {object} source - The source object to log into console
   * @param {bool} showToast - Show toast using Toastr.Notify
   */
  log(message: string, data: any, source: any, displayType: ToastDisplay | LogDisplay = ToastDisplay.Info, showToast: boolean = false, timeOut: number = undefined): void {
    if (showToast) {
      this.logIt(message, data, source, showToast, ToastDisplay.Info, timeOut);
    } else {
      switch (displayType) {
        case LogDisplay.Info:
          this.info(source, message, data);
          break;
        case LogDisplay.Debug:
          this.debug(source, message, data);
          break;
        case LogDisplay.Warn:
          this.warn(source, message, data);
          break;
        case LogDisplay.Error:
          this.error(source, message, data);
          break;
      }
    }
  }

  /**
   * Log Error message
   * @method
   * @param {string} message
   * @param {object} data - The data object to log into console
   * @param {object} source - The source object to log into console
   * @param {bool} showToast - Show toast using Toastr.Notify
   */
  logError(message: string, data: any, source: any, showToast: boolean = false, timeOut: number = undefined): void {
    this.logIt(message, data, source, showToast, ToastDisplay.Error, timeOut);
  }

  /**
   * Log Success message
   * @method
   * @param {string} message
   * @param {object} data - The data object to log into console
   * @param {object} source - The source object to log into console
   * @param {bool} showToast - Show toast using Toastr.Notify
   */
  public logSuccess(message: string, data: any, source: any, showToast: boolean = false, timeOut: number = undefined): void {
    this.logIt(message, data, source, showToast, ToastDisplay.Success, timeOut);
  }

  /**
   * Log Warning message
   * @method
   * @param {string} message
   * @param {object} data - The data object to log into console
   * @param {object} source - The source object to log into console
   * @param {bool} showToast - Show toast using Toastr.Notify
   */
  public logWarn(message: string, data: any, source: any, showToast: boolean = false, timeOut: number = undefined): void {
    this.logIt(message, data, source, showToast, ToastDisplay.Warn, timeOut);
  }

  /**
   * Log Information message
   * @method
   * @param {string} message
   * @param {object} data - The data object to log into console
   * @param {object} source - The source object to log into console
   * @param {bool} showToast - Show toast using Toastr.Notify
   */
  public logInfo(message: string, data: any, source: any, showToast: boolean = false, timeOut: number = undefined): void {
    this.logIt(message, data, source, showToast, ToastDisplay.Info, timeOut);
  }

  /**
   * Logs the message from the public methods
   * @method
   * @private
   * @param {string} message
   * @param {object} data - The data object to log into console
   * @param {object} source - The source object to log into console
   * @param {bool} showToast - Show toast using Toastr.Notify
   */
  logIt(message: string, data: any, source: any, showToast: Boolean,
    toastType: ToastDisplay, timeOut: number = undefined): void {
    if (data) {
      if (toastType === ToastDisplay.Error) {
        this.error(source, message, data);
      } else {
        this.info(source, message, data);
      }
    } else {
      if (toastType === ToastDisplay.Error) {
        this.error(source, message);
      } else {
        this.info(source, message);
      }
    }
    if (showToast) {
      // showAccountWarning sets onclick, so we reset it.
      this.defaultToastOptions.onclick = null;
      var options = this.defaultToastOptions;
      if (timeOut !== undefined) {
        options.timeOut = timeOut;
        if (timeOut === 0) {
          options.closeButton = true;
        }
      }
      switch (toastType) {
        case ToastDisplay.Error:
          options.timeOut = 0;
          options.closeButton = true;
          //          this.mdToastService.show(message, this.defaultToastOptions.showDuration, "error");
          toastr.error(message, "Error!", this.defaultToastOptions);
          break;
        case ToastDisplay.Success:
          //          this.mdToastService.show(message, this.defaultToastOptions.showDuration, "success");
          toastr.success(message, "Success !!", options);
          break;
        case ToastDisplay.Warn:
          var options = this.defaultToastOptions;
          //          this.mdToastService.show(message, this.defaultToastOptions.showDuration, "warn");
          toastr.warning(message, "Warning", options);
          break;
        case ToastDisplay.Info:
          //          this.mdToastService.show(message, this.defaultToastOptions.showDuration);
          toastr.info(message, "Information", options);
          break;
        default:
          toastr.info(message, "Information", this.defaultToastOptions);
          break;
      }
    }
  }

  resendEmail(): void {
    alert("Resend email");
  }
}
