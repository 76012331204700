import { ChatMessageType, ChatState } from "../common/enums";

export class AppNotificationModel {
  public senderId: string;
  public senderName: string;
  public senderImage: string;
  public receipentId: string;
  public chatMessageType: ChatMessageType;
  public chatState: ChatState;
  public message: string;
  public sentDate: Date;

  constructor(senderName: string, senderId: string,
    receipentId: string,
    chatMessageType: ChatMessageType,
    message: string, chatState = ChatState.Request) {
    this.senderName = senderName;
    this.senderId = senderId;
    this.receipentId = receipentId;
    this.chatMessageType = chatMessageType;
    this.message = message;
    this.chatState = chatState;
    this.sentDate = new Date();
    this.senderImage = "";
  }
}