import { RouteModelBase } from "./route-model-base";
import { INotificationEventTarget } from "common/interfaces";
import { BallotStatus, SaleAction, OrderStatus, GooglePurchaseType } from "common/enums";
import { SubscriptionPlan } from "model/store/subscription-plan";
import { TOUViolation } from "model/d4c/tou-violation";
import { Content } from "model/d4c/content";
import { CompatibleMatch } from "model/d4c/compatible-match";
import { MatchHistory } from "model/d4c/match-history";
import { IGoogleSubscriptionPlan } from "web-pay/google/model/interfaces";
import { IAppleSubscriptionPlan } from "web-pay/apple/model/interfaces";
import { IPayPalSubscriptionPlan } from "web-pay/paypal/model/interfaces";

export class EmailConfirmation extends RouteModelBase {
  confirmationUrl: string;
  email: string;
  constructor() {
    super();
    this.confirmationUrl = "";
    this.email = "";
  }
}

export class AddExternalLoginBindingModel {
  public externalAccessToken: string;
}

export class AccountConfirmation extends RouteModelBase {
  public userId: string;
  public code: string;
}

export class AccountNotificationModel extends RouteModelBase {
  public userId: string;
  public displayName: string;
  public code: string;
  public url: string;
  public clientAppUrl: string;
  public email: string;
  public password: string;
  public messageBody: string;
}

export class SettingsViewModel {
  public simulateEmail: boolean;
  public enableOAuth: boolean;
}

export class ProfileTagBindingModel {
  public tagCode: number;
  public description: string;
}

export class SetPasswordBindingModel {
  public userName: string;
  public newPassword: string;
  public confirmPassword: string;
}
export class RemoveLoginModel {
  public loginProvider: string;
  public providerKey: string;
}


export class RegisterBindingModel extends RouteModelBase {
  public userName: string;
  public password: string;
  public confirmPassword: string;
  public email: string;
  public preferredName: string;
  public birthDate: Date;
  public gender: string;
  public seekGender: string;
  public termsAccepted: boolean;
  public confirmationUrl: string;
  public profileImageId: string;
  public age: number;
  public likesDislikes: string; // serialized representation
  public aboutYou: string;
  public aboutYouShort: string;
  public city: string;
  public state: string;
  public zip: number;
  public profileTags: ProfileTagBindingModel[];
  public startAge: number;
  public endAge: number;

  constructor() {
    super();
  }
  setUserName() {
    if (this.userName === "") {
      // assign email name.
      this.userName = this.email.substr(0, this.email.indexOf("@"));
    }
  }
}

export class ForgotPasswordModel {
  public email: string;
  public confirmationUrl: string;
  constructor(email: string) {
    this.email = email;
    this.confirmationUrl = "";
  }
}

export class NotificationBindingModel implements INotificationEventTarget {
  ballotStatus: BallotStatus;
  notificationId: number;
  ballotId: number;
  senderId: string;
  senderPreferrName: string;
  externalId: string;
  constructor(eventTargetData: INotificationEventTarget, ballotStatus: BallotStatus) {
    this.ballotStatus = ballotStatus;
    this.notificationId = eventTargetData.notificationId;
    this.ballotId = eventTargetData.ballotId;
    this.senderId = eventTargetData.senderId;
    this.senderPreferrName = eventTargetData.senderPreferrName;
    this.externalId = eventTargetData.externalId;
  }
}

export class SubscriberPlanBindingModel {
  public userId: string;
  public plan: SubscriptionPlan;
}

export class SaleModificationBindingModel {
  public userId: string;
  public id: string;
  public action: SaleAction;
  public note: string;
}

export class SubscriptionHistoryBindingModel {
  public actionDate: Date;
  public action: number;
  public actionDescription: string;
  public reason: string;
  public firstName: string;
  public lastName: string;
}

export class UserSubscriptionHistoryBindingModel {
  public subscriptionId: number;
  public startDate: Date;
  public expirationDate: Date;
  public planId: string;
  public name: string;
  public description1: string;
  public active: boolean;
  public deepLink: string;
  public purchaseToken: string;
}

export class WebHookBindingModel {
  public name: String;
  public eventType: String;
  constructor(name: string, eventType: string) {
    this.name = name;
    this.eventType = eventType;
  }
}

export class WebProfileBindingModel {
  public id: String;
  public name: String;
  constructor(id: string, name: string) {
    this.name = name;
    this.id = id;
  }
}

export class CaptchaVerify {
  public token: string;
}

export class ConversationViewed {
  public senderId: string;
  public receipentId: string;
  public viewed: Boolean;
}

export class ConversationIgnoreReport {
  public ignore: Boolean;
  public touViolation: TOUViolation;
  public senderId: string;
  public receipentId: string;
}

export class ConversationUnmatch {
  public senderId: string;
  public receipentId: string;
  public delete: boolean;
}

export class EncryptedData {
  public name: string;
  public data: string;
  public personalContent: string[] | Content[];
  public thirdPersonContent: string[] | Content[];
  public relationshipContent: string[] | Content[];
  public relThirdPersonContent: string[] | Content[];
  public compatibleMatches: CompatibleMatch[];
  public matchHistory: MatchHistory[];
}

export class TrackBindingModel {
  public code: string;
  public typeCode: string;
  public oS: string;
  public data: string;
}

export class GoogleSubscriptionPlanBindingModel implements IGoogleSubscriptionPlan {
  public sku: string;
  public name: string;
  public description: string;
  public benefits: string[];
  public currencyCode: string;
  public price: number;
  public isSubscribed: boolean;
  public billingPeriodDuration: string;
  public orderStatus: OrderStatus;
  public purchaseType: GooglePurchaseType;

  constructor(sku: string) {
    this.sku = sku;
  }
}

export class AppleSubscriptionPlanBindingModel implements IAppleSubscriptionPlan {
  public sku: string;
  public name: string;
  public description: string;
  public benefits: string[];
  public currencyCode: string;
  public price: number;
  public isSubscribed: boolean;
  public billingPeriodDuration: string;
  public orderStatus: OrderStatus;
  public purchaseType: GooglePurchaseType;

  constructor(sku: string) {
    this.sku = sku;
  }
}

export class ContactViewModel {
  public captchaCode: string;
  public captchaImage: string;
  public subject: string;
  public email: string;
  public source: string;
  public comment: string;
  public extraInformation: string;

  constructor() {
    this.captchaCode = "";
    this.captchaImage = "";
    this.subject = "";
    this.email = "";
    this.source = "spotonlove.com";
    this.comment = "";
    this.extraInformation = "";
  }
}