import { Profile } from "../model/d4c/profile";
import moment from "moment";
/**
 * @module Several utils for the app
 */
export class Utils {
  /**
   * Get parameters from url
   * @param {string} name - The name of the parameter
   * @return {string}
   */
  getUrlParameter(name: string): string {
    return decodeURI(
      (RegExp(name + "=" + "(.+?)(&|$)").exec(location.href) || [, null])[1]
    );
  };

  /**
   * Add items to knockout foreach bindings with an animation
   * @param {string} elem - The element to add
   */
  addItemAnimation(elem: any): void {
    if (elem.nodeType === 1) {
      $(elem).addClass("animated fadeInLeft");
    }
  };

  /**
   * Remove items to knockout foreach bindings with an animation
   * @param {string} elem - The element to remove
   */
  removeItemAnimation(elem: any): void {
    if (elem.nodeType === 1) {
      $(elem).addClass("animated fadeOutLeft");
      setTimeout(function () {
        $(elem).remove();
      }, 500);
    }
  };

  /** 
   * Get current date
   * @return {Date} - Current date
   */
  getCurrentDate = (): string => {
    return moment.utc().format();
  };

  /**
   * Provisional version returns validation error messages 
   * of first entity that failed to save
   * @param {object} saveError - The save error object
   * @return {string} - Error validation message
   */
  getSaveValidationErrorMessage(saveError: any): string {
    try { // return the first entity"s error message(s)
      var firstError = saveError.entityErrors[0];
      return "Validation Error: " + firstError.errorMessage;
    } catch (e) { // ignore problem extracting error message 
      return "Save validation error";
    }
  };

  /**
   * Return string of an entity's validation error messages 
   * @param {entity} entity
   * @return {string} - Error messages
   */
  getEntityValidationErrorMessage(entity: any): string {
    try {
      var errs = entity.entityAspect.getValidationErrors();
      var errmsgs = errs.map(function (ve: any) { return ve.errorMessage; });
      return errmsgs.length ? errmsgs.join("; ") : "no validation errors";
    } catch (e) {
      return "not an entity";
    }
  };

  /**
   * Detect the Bootstrap environment being used
   * @return {string} - Environment
   */
  findBootstrapEnvironment(): string {
    var envs = ["xs", "sm", "md", "lg"];

    var $el = $("<div>");
    $el.appendTo($("body"));

    for (var i = envs.length - 1; i >= 0; i--) {
      var env = envs[i];

      $el.addClass("hidden-" + env);
      if ($el.is(":hidden")) {
        $el.remove();
        return env;
      }
    };
  }

  /**
   * Check if some url is external
   * @return true/false
   */
  isExternal(url: string): boolean {
    var protocol: any = { "http:": 80, "https:": 443 };
    var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
    if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) { return true; };
    if (typeof match[2] === "string" && match[2].length > 0 && match[2]
      .replace(new RegExp(":(" + protocol[location.protocol] + ")?$"), "") !== location.host) { return true; };
    return false;
  }

  /**
   * Check required user application profile is completed
   * @return true/false
   */
  hasCompleteProfile(aProfile: Profile): boolean {
    if ((aProfile.preferredName !== "" &&
      aProfile.preferredName !== null) &&
      (aProfile.birthDate !== null) &&
      (aProfile.gender !== null)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check user has accepted the term of use.
   * @return true/false
   */
  hasAcceptedTerms(aProfile: Profile): boolean {
    if (aProfile.termsIp === ""
      || aProfile.termsIp === null) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check user has accepted the term of use.
   * @return true/false
   */
  hasAcceptedPrivacyTerms(aProfile: Profile): boolean {
    if (aProfile.privacyRead === null) {
      return false;
    } else {
      return true;
    }
  }

  JDecimalDays(date: Date): number {
    return date.getMilliseconds() / 86400000 + 2440587.5;
  }

  JDays(date: Date): number {
    return Math.floor(date.getMilliseconds() / 86400000 + 2440587.5);
  }
  JDUTC(Y: number, M: number, D: number, H: number, m: number, s: number, ms: number) {
    // m is Jan = 0, Feb = 1, etc.
    // add local hour offset to `H` or minute offset to `m` for local time 
    return Date.UTC.apply(Date, arguments) / 86400000 + 2440587.5;
  }

  JulianToUTCDate(jd: number): Date {
    var utcDate: Date = new Date();
    utcDate.setTime((jd - 2440587.5) * 86400000);
    return utcDate;
  }

  JulianToLocalDate(jd: number): Date {
    var localDate = new Date();
    if (jd !== null) {
      // var always get the standard time zone offset.
      var jan = new Date(localDate.getFullYear(), 0, 1);
      var jul = new Date(localDate.getFullYear(), 6, 1);
      var timezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
      localDate.setTime((jd - 2440587.5) * 86400000);
      localDate.setMinutes(localDate.getMinutes() + timezoneOffset);
    } else {
      localDate = null;
    }
    return localDate;
  }

  LocalDateToJullian(aDate: any): number {
    // minimum Julian date is - 0.5
    var lRtn: number = -1;
    if (aDate !== null) {
      var date: Date;
      var timezoneOffset = new Date().getTimezoneOffset();
      switch (typeof (aDate)) {
        case "string":
          var from = aDate.split("/").map(Number);
          date = new Date(from[2], from[0] - 1, from[1]);
          break;
        default:
          date = aDate;
          break;
      }
      lRtn = Math.round((date.getTime() - timezoneOffset) / 86400000 + 2440587.5) - 0.5;
    }
    return lRtn;
  }
}

