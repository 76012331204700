export enum ErrorDisplayType {
  Dialog,
  None,
  Toast
}

export enum OAuthErrorType {
  InvalidGrand = "invalid_grant",
  InactiveAccount = "inactive_account"
}

export enum ToastDisplay {
  Error,
  Info,
  Success,
  Warn
}

export enum LogDisplay {
  Error,
  Info,
  Debug,
  Warn
}

export enum AccessMode {
  HRClerk,
  User
}

export enum AttractionType {
  Inconclusive,
  OneAttractedToTwo,
  TwoAttactedToOne,
  Unknown
}

export enum Gender {
  Male = "M",
  Female = "F"
}

export enum SeekGender {
  Both = "B",
  Male = "M",
  Female = "F"
}

export enum NotificationType {
  D4CProfile,
  Terms,
  BroadcastMessage
}

export enum NotificationDisplay {
  Default,
  HomePage,
  Web
}

export enum NotificationPermissionStatus {
  Ask = "default",
  Allow = "granted",
  Block = "denied",
  Undefined = "undefined"
}

export enum NotificationActionResult {
  Navigate = "pn-navigate",
  Message = "pn-message",
  Event = "pn-event"
}

export enum MatchType {
  Match = "M",
  History = "H",
  Vote = "V"
}

export enum MatchDisplayType {
  Favorite = "F",
  History = "H",
  Compatibility = "C"
}

export enum ProfileLikeType {
  Dislike,
  Like
}

export enum TagTypeEnum {
  NoSelection = 0,
  Exercise,
  Education,
  Drinking,
  Smoking,
  Cannabis,
  Pets,
  Children,
  Religion,
  Political
}

export enum ContentType {
  PersonalLong = 1,
  PersonalShort,
  ThirdPersonLong,
  ThirdPersonShort,
  RelationshipLong,
  RelationshipShort,
  RelationshipFun,
  RelationshipThirdPersonLong,
  RelationshipThirdPersonShort,
  PersonalProfile,
  FamousPeople,
  Election2012Personal,
  Election2012PersonalProfile
}

export enum BreezeQueryType {
  Entity,
  ServerSide
}

export enum ConfigurationMode {
  LocalDevHttp,
  LocalDevHttps,
  Staging,
  Production
}

export enum ChatMessageType {
  Login,
  Logout,
  Request,
  DeclineRequest,
  Message,
  ChatDeclined,
  Disconnected
}

export enum ChatState {
  Request,
  RequestAccept,
  RequestDecline,
  Connected,
  Disconnected,
  Inactive
}

export enum ChatMessageDirection {
  Receive = "R",
  Send = "S"
}

export enum SignalRState {
  Connecting,
  Connected,
  Reconnecting,
  NotUsed,
  Disconnected
}

export enum RegisterView {
  Step1 = "account/register/register-step1.html",
  Step2 = "account/register/register-step2.html",
  Step3 = "account/register/register-step3.html",
  Success = "account/register/register-step4.html"
}

export enum PwaPromptAction {
  Add = "Add",
  Dimiss = "Dimiss",
  Defer = "Defer",
  Preview = "Preview",
  Help = "Help"
}

export enum PWAInstallType {
  AppStore = "appstore",
  None = "none",
  Web = "web",
  PlayStore = "twa"
}

export enum PWADisplayMode {
  Browser = "browser",
  StandAlone = "stand-alone",
  TWA = "twa"
}

export enum AnalyticType {
  CompatibilitySpotLight = "SL",
  PWAInstall = "PI"
}

export enum EventMessageType {
  AppMessageReceived = "app-message-received",
  AppInitialized = "app-initialize",
  CarouselClosed = "carousel-closed",
  CarouselCycled = "carousel-cycled",
  ChatRequestAccept = "chat-request-accept",
  ChatRequestDecline = "chat-request-decline",
  ChatMessageReceived = "chat-message-received",
  ChatMessageSent = "chat-message-sent",
  CloseCollapsible = "close-collapsible",
  CloseAnywhere = "wi-close",
  NetWorkChange = "netWorkStatusChanged",
  NotificationMessage = "notification-message",
  BadgeSet = "badge-set",
  BadgeClear = "badge-clear",
  BadgeDecrement = "badge-decrement",
  BadgeIncrement = "badge-increment",
  BreezeInitialized = "breeze-initialized",
  NextCarouselPerson = "next-carousel-person",
  PrevCarouselPerson = "prev-carousel-person",
  ShowHelp = "show-help",
  AllowVote = "pn-allow-voting",
  DenyVote = "pn-deny-voting",
  NewMatch = "pn-new-match",
  NewMatchInterest = "pn-new-match",
  DimissNotification = "pn-dimiss-notification",
  DimissImageNotification = "pn-dimiss-image-notification",
  NotApplicable = "not-applicable",
  OpenFilterMenu = "open-filter-menu",
  SharePage = "share-page",
  ShareDialogClosed = "share-dialog-closed",
  ScanImage = "scan-image",
  IgnoreMatch = "ignore-match",
  ReportMatch = "report-match",
  PerssonDataUpdated = "person-data-update",
  InstallApp = "install-app",
  CroppedImageCancel = "crop-image-cancel",
  CroppedImageRecrop = "crop-image-recrop",
  CroppedImageSrcSet = "crop-image-src-set",
  CroppedImageUploaded = "crop-image-uploaded",
  UnitOfWorkInitialized = "unit-of-work-initialized",
  CalendarYearLimit = "calendar-year-limit",
  MatchFilterStatus = "match-filter-status",
  ModalDatePickerClosed = "modal-date-picker-closed",
  UpdateRoutes = "update-routes",
  PageLoaded = "page-loaded",
  // Apple result messages
  ApplePurchase = "apple-purchase",
  AppleGetProducts = "apple-get-products",
  AppleGettransactions = "apple-get-transactions",
  ApplePurchaseTransaction = "apple-get-purchase-transaction",
  AppleSubscriptionGroupLoad = "apple-subscription-group-load",
  AppleSubscriptonVerified = "apple-subscription-verified"
}

export enum BadgeNotificationType {
  All = "badge-all",
  AppNotification = "badge-app-notifiction",
  Message = "badge-message"
}
/**
 * Cache key enumeration to avoid hard coding key values.
 */
export enum CacheKey {
  AccessRestraints = "access-restraints",
  Analytics = "analytics",
  ApiVersion = "apiVersion",
  AppVersion = "appVersion",
  AssociatingExternalLogin = "associatingExternalLogin",
  BreezeInitialized = "breeze-initialized",
  ChatTabs = "chatTabs",
  ConversationTabs = "conversationTabs",
  Theme = "colorTheme",
  JWT = "accessToken",
  ExternalToken = "external-token", //use to added external logins
  LoginURL = "loginUrl",
  LastLoginDate = "lastLoginDate",
  ReDirect = "redirectTo",
  RegisterBindingModel = "registerBindingModel",
  SessionStorageBackup = "sessionStorageBackup",
  State = "state",
  UserInformation = "userInfo",
  WorkColorTheme = "workColorTheme",
  SessionState = "sessionState",
  MatchPercentage = "matchPercentage",
  RedirectRoute = "redirectRoute",
  SimulateEmail = "simulateEmail",
  matchFilter = "matchFilter",
  pwaInstallType = "pwaInstallType",
  pwaPromptDecision = "pwaPromptDecision",
  testResults = "testResults"
}

export enum AnimationSequence {
  Both,
  EnterOnly,
  LeaveOnly
}

export enum MatchDataSource {
  AnonymousUser,
  SeekPage
}

export enum HelpTopic {
  CEAuthenticated = "ce-authenticated",
  CEAnonymous = "ce-anonymous",
  SeekInstructions = "seek-instructions",
  SettingsDOB = "settings-dob",
  WhosChasingWho = "whos-chasing-who",
  IOSPWAPrompt = "ios-pwa-prompt"
}

export enum RouteCategoryTitle {
  Anonymous = "Anonymous",
  Authorized = "Authorized",
  Dynamic = "Dynamic",
  General = "General",
  Experiment = "Experiment",
  WebPay = "WebPay"
}

export enum AttractionProNoun {
  None = 0,
  FirstPerson = 1,
  ThirdPerson = 2
}
export enum TOUViolationType {
  None = "None",
  Pending = "Pending",
  UnderReview = "UnderReview",
  Reviewed = "Reviewed",
  Unmatched = "Unmatched"
}

export enum UserVoteType {
  Up = "UP",
  Down = "DOWN",
  Netural = "NETURAL"
}
export enum SendToJury {
  Allow = 0,
  Ask = 1,
  Deny = 2
}
export enum BallotStatus {
  Allow = "allow",
  Deny = "deny",
  Pending = "pending"
}

export enum OrderStatus {
  Active = 1,
  Canceled = 2,
  Confirmed = 3,
  Processing = 4,
  PaymentCanceled = 5,
  Suspended = 6,
  WaitingForPayment = 7,
  Refunded = 8,
  Reversed = 9
}

export enum SaleAction {
  Cancel = 1,
  Complete = 2,
  Dispute = 3,
  Reactivate = 4,
  Refund = 5,
  Reverse = 6,
  Suspend = 7,
  Unknown = 8
}

export enum WebHookEventType {
  SaleRefunded = "PAYMENT.SALE.REFUNDED",
  SaleReversed = "PAYMENT.SALE.REVERSED",
  DisputeCreated = "CUSTOMER.DISPUTE.CREATED",
  SubscriptionCancelled = "BILLING.SUBSCRIPTION.CANCELLED",
  SubscriptionSuspended = "BILLING.SUBSCRIPTION.SUSPENDED",
  SubscriptionReactivated = "BILLING.SUBSCRIPTION.RE-ACTIVATED"
}

export enum WebHookProcessStatus {
  Complete = 0,
  Pending = 1,
  SaleNotFound = 2,
  SubscriptionNotFound = 3
}

export enum SubscriptionPlanState {
  Active = "ACTIVE",
  Created = "CREATED",
  Inactive = "INACTIVE",
  Pending = "PENDING"
}

export enum SubscriptionState {
  SUBSCRIPTION_STATE_UNSPECIFIED = 0,
  SUBSCRIPTION_STATE_PENDING = 1,
  SUBSCRIPTION_STATE_ACTIVE = 2,
  SUBSCRIPTION_STATE_PAUSED = 3,
  SUBSCRIPTION_STATE_IN_GRACE_PERIOD = 4,
  SUBSCRIPTION_STATE_ON_HOLD = 5,
  SUBSCRIPTION_STATE_CANCELED = 6,
  SUBSCRIPTION_STATE_EXPIRED = 7
}

export enum UserRoles {
  Administrator = "Administrator",
  ServiceClerk = "ServiceClerk",
  User = "User"
}
export enum PersonalProfileArraySeq {
  Name = 0,
  Soul,
  Spirt
}

export enum PaymentApiControllers {
  BillingPlans = "billingplans",
  ECommerceController = "ecommerce",
  Sale = "sale",
  SinglePayment = "singlepayment",
  Subscribers = "ssubscribers",
  Subscription = "subscription"
}

export enum PaymentResponsAction {
  CancelTransaction = "canceltransaction",
  ReturnTransaction = "returntransaction"
}

export enum PaymentProvider {
  ApplePay = "apple-pay",
  PlayBilling = "play-billing",
  PayPal = "pay-pal"
}

export enum SupplementalType {
  RespectVote = 0,
  ContestedPicture = 1
}

// to add a new theme add the .theme-name to the
// theme SCSS file and them adjust shell logic to
// use theme. 
export enum Theme {
  Standard = ".theme-standard",
  Dark = ".theme-dark",
  Device = ".theme-device"
}

export enum ThemeMode {
  Light = "light-theme",
  Dark = "dark-theme",
  System = "system-theme"
}

export enum MatchFilter {
  MatchPercentage = "Minimum Match",
  Favorites = "Favorites",
  New = "New Matches",
  History = "History",
  Interested = "Interested Matches",
  Ignored = "Ignored Matches",
  Maybe = "Maybe Matches",
  Distance = "Distance"
}

export enum ImageStatus {
  Normal = 0,
  UnderReview = 1,
  Inappropriate = 2,
  NotScanned = 3
}

export enum PrefersColorScheme {
  Light = "light",
  Dark = "dark",
  NotAll = "not all"
}

export enum ModalTemplate {
  Standard = "standard",
  Slider = "slider",
  Smilie = "smilie"
}

export enum FeedbackTemplate {
  Standard = "standard",
  Smiley = "smiley"
}

export enum SwipeDirection {
  Left = 0,
  Right = 1,
  Up = 2,
  Down = 3
}

export enum ProfileVisibility {
  Restricted = 0, // Non subscribing user
  Normal = 1,
  Incognito = 2,
  Snooze = 3,
  Deleted = 4
}

export enum ProfileStartupView {
  UserDashboard = "user-dashboard", // Default
  UserConversations = "user-conversations",
  MatchTabView = "user-single-match",
  MatchCollapisableVeiw = "user-single-match-extended"
}

export enum MatchInterestLevel {
  NotInterested = 0,
  Interested = 1,
  Perhaps = 2
}

export enum EntityName {
  Ballot = "Ballot",
  CompatibleMatch = "CompatibleMatch",
  Conversation = "Conversation",
  ConversationHistory = "ConversationHistory",
  Contact = "Contact",
  Notification = "Notification",
  MatchHistory = "MatchHistory",
  TOUViolation = "TOUViolation",
  Article = "Article",
  Profile = "Profile",
  ProfileImage = "ProfileImage",
  TmpProfileImage = "TmpProfileImage",
  ProfileTag = "ProfileTag",
  Help = "Help",
  PushTextType = "PushTextType",
  TagType = "TagType",
  UserVote = "UserVote",
  VoteSummary = "VoteSummary",
  WirelessCarrier = "WirelessCarrier",
  Location = "Location",
  SupplementalUserData = "SupplementalUserData"
}
//stateConversion = { 0: "connecting", 1: "connected", 2: "reconnecting", 4: "disconnected" };

export enum SideMenu {
  None = 0,
  ModalAndSide = 1,
  ModalOnly = 2,
  SideOnly = 3
}

export enum RequestMode {
  CORS = "cors",
  NOCORS = "no-cors",
  SAMEORGIN = "same-origin",
  NAVIGATE = "navigate",
  WEBSOCKET = "websocket"
}

export enum SubscriptionPurchaseState {
  NONE = 0,
  PROCESSING = 1,
  VALIDATING = 2
}

export enum GooglePurchaseType {
  PURCHASE = "purchase",
  SUBSCRIPTION = "subscription"
}

export enum APITestMode {
  Apple,
  Google,
  PayPal,
  None
}

export enum MockTestMode {
  AlwaysSucceeds,
  AlwaysFails,
  None
}

export enum PaymentProcessor {
  ApplePay,
  GooglePay,
  InstallationDefault,
  PayPal
}

export enum AppleProductKind {
  Consumable = "Consumable",
  NonConsumable = "Non-Consumable",
  AutoRenewableSubscription = "Auto-Renewable Subscription",
  NonRenewingSubscription = "Non-Renewing Subscription"
}

export enum TestDataProperty {
  ApplePaymentToken = "applePaymentToken",
  GooglePaymentToken = "googlePaymentToken",
  PayPalPaymentToken = "payPalPaymentToken",
  SaveOnly = "saveOnly",
  TestError = "testError"
}