import { PLATFORM } from "aurelia-pal";
import { Aurelia, Container } from "aurelia-framework";
import * as LogManager from "aurelia-logging";
import { ConsoleAppender } from "aurelia-logging-console";
import { AppSecurity } from "services/appsecurity";
import environment from "./environment";
//Velocity configuration moved toastr infrasture.
// Styles, font awesome and toastr css moved to public root.

// to enable IE 11 promise library uncomment the line in the webpack.config.js

export async function configure(aurelia: Aurelia) {
  // Aurelia add a default ConsoleAppender so we don't nee3d to add it.
  LogManager.setLevel(LogManager.logLevel.debug);

  await aurelia.loader.loadModule(PLATFORM.moduleName("materialize-css"));
  // The below is the current maintained version, but this will require updating
  /// SASS components and testing.
  //await aurelia.loader.loadModule(PLATFORM.moduleName("@materializecss/materialize"));
  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('@appex/aurelia-dompurify'))
    .plugin(PLATFORM.moduleName("aurelia-breeze"))
    // moved breeze validator and velocity moved to infrastructur module. This
    // was done to improve the initial load time.
    // none / error / warn / info / debug
    .plugin(PLATFORM.moduleName("aurelia-dialog"), cfg => {
      cfg.useDefaults()
        .useCSS("");
    })
    .plugin(PLATFORM.moduleName("aurelia-materialize-bridge"), (bridge: any) => {
      //if (window.spotOnLovePolyfills) {
      //  bridge
      //    .useModal()
      //    .useInput()
      //    .useDatePicker();

      //} else {
      //  bridge.useAll();
      //}
      bridge.useAll();
    })
    .feature(PLATFORM.moduleName("resources/validation/index", "publicuser"))
    .feature(PLATFORM.moduleName("resources/index", "publicuser"))
    //.feature(PLATFORM.moduleName("home/experimental/index", "appmain"))
    ;

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName("aurelia-animator-css"));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName("aurelia-html-import-template-loader"));

  if (environment.debug) {
    console.warn("** Aurelia debugging enabled **")
    aurelia.use.developmentLogging("debug");
  } else {
    aurelia.use.developmentLogging("warn");
  }

  if (environment.testing) {
    console.warn("** Aurelia testing enabled **");
    aurelia.use.plugin(PLATFORM.moduleName("aurelia-testing"));
  }

  // start up
  //user root
  PLATFORM.moduleName("shell/roots/user", "usermain");
  PLATFORM.moduleName("shell/roots/user-routes.ts", "usermain");

  //public root
  PLATFORM.moduleName("shell/roots/public", "appstart");
  PLATFORM.moduleName("shell/roots/public-routes.ts", "appstart");
  PLATFORM.moduleName("home/index", "appstart");

  PLATFORM.moduleName("common/view-model-base.ts", "appstart");
  PLATFORM.moduleName("common/view-model-public-base.ts", "appstart");
  PLATFORM.moduleName("services/logger", "appstart");
  PLATFORM.moduleName("common/enums", "appstart")
  PLATFORM.moduleName("services/cache-service", "appstart")
  
  PLATFORM.moduleName("services/startup-service", "publicuser")
  PLATFORM.moduleName("shell/app-config", "publicuser");
  PLATFORM.moduleName("services/infrastructure", "publicuser");

  PLATFORM.moduleName("account/register/register-step1.html", "publicuser");
  PLATFORM.moduleName("account/register/register-step2.html", "publicuser");
  PLATFORM.moduleName("account/register/register-step3.html", "publicuser");
  PLATFORM.moduleName("account/register/register-step4.html", "publicuser");
  PLATFORM.moduleName("account/registration-complete/registration-complete", "publicuser");
  PLATFORM.moduleName("account/external-login-failure/external-login-failure", "publicuser");
  PLATFORM.moduleName("account/reset-password/reset-password", "publicuser");
  PLATFORM.moduleName("account/login/login", "publicuser");
  PLATFORM.moduleName("home/about/about", "publicuser");
  PLATFORM.moduleName("home/help/help", "publicuser");
  PLATFORM.moduleName("home/copyright/copyright", "publicuser");
  PLATFORM.moduleName("home/correspondence/correspondence-service", "publicuser")
  PLATFORM.moduleName("home/correspondence/correspondence", "publicuser");
  PLATFORM.moduleName("home/correspondence/correspondence-confirmation", "publicuser");
  PLATFORM.moduleName("user/feedback/feedback", "usermain");
  PLATFORM.moduleName("user/notification/notification-list", "usermain");

  PLATFORM.moduleName("home/disclaimer/disclaimer", "publicuser");
  PLATFORM.moduleName("home/privacy/privacy", "publicuser");
  PLATFORM.moduleName("home/terms/terms", "publicuser");
  PLATFORM.moduleName("home/site-minimums/site-minimums", "publicuser");
  PLATFORM.moduleName("home/testimonial/testimonial", "publicuser");

  PLATFORM.moduleName("user/compatibility-spotlight/compatibility-spotlight", "publicuser");
  PLATFORM.moduleName("user/profile/profile-service", "publicuser");

  PLATFORM.moduleName("error/error404", "publicuser");
  PLATFORM.moduleName("account/unauthorized/unauthorized", "publicuser");
  PLATFORM.moduleName("resources/dialogs/a2hs-prompt/ios-a2hs-prompt.html", "publicuser");
  PLATFORM.moduleName("resources/dialogs/a2hs-prompt/android-a2hs-prompt.html", "publicuser");
  PLATFORM.moduleName("resources/elements/nav-bar", "appstart");
  PLATFORM.moduleName("model/d4c/location", "publicuser");
  
  // main
  PLATFORM.moduleName("account/manage/manage-account", "usermain");
  PLATFORM.moduleName("admin/utilities/helpers", "usermain");
  PLATFORM.moduleName("account/reset-password/reset-password", "usermain");
  PLATFORM.moduleName("resources/dialogs/tou-violation/tou-violation-dialog", "usermain");
  PLATFORM.moduleName("user/test/inspect", "usermain");
  PLATFORM.moduleName("user/test/inspect.html", "usermain");

  // webpay
  PLATFORM.moduleName("web-pay/persistent-subscription", "webpay");
  PLATFORM.moduleName("web-pay/paypal/cancel-payment/cancel-payment", "webpay");
  PLATFORM.moduleName("web-pay/paypal/ecommerce-payment/ecommerce-payment", "webpay");
  PLATFORM.moduleName("web-pay/paypal/ecommerce-payment/checkout", "webpay");
  PLATFORM.moduleName("web-pay/paypal/ecommerce-payment/shopping-cart", "webpay");
  PLATFORM.moduleName("web-pay/paypal/ecommerce-payment/ecommerce-payment-service", "webpay");
  PLATFORM.moduleName("web-pay/paypal/payment-thankyou/payment-thankyou", "webpay");
  PLATFORM.moduleName("web-pay/paypal/single-payment/single-payment", "webpay");
  PLATFORM.moduleName("web-pay/paypal/single-payment/single-payment-service", "webpay");
  PLATFORM.moduleName("web-pay/paypal/fixed-length-subscription/fixed-length-subscription", "webpay");
  PLATFORM.moduleName("web-pay/paypal/fixed-length-subscription/fixed-length-subscription-service", "webpay");
  PLATFORM.moduleName("web-pay/paypal/payment-thankyou/payment-thankyou", "webpay");
  PLATFORM.moduleName("web-pay/paypal/persistent-subscription/paypal-persistent-subscription", "webpay");
  //PLATFORM.moduleName("web-pay/paypal/persistent-subscription/persistent-subscribe-to-plan", "webpay");
  PLATFORM.moduleName("web-pay/paypal/persistent-subscription/paypal-persistent-subscription-service", "webpay");

  PLATFORM.moduleName("web-pay/google/persistent-subscription/google-persistent-subscription", "webpay");
  //PLATFORM.moduleName("web-pay/google/persistent-subscription/google-persistent-subscribe-to-plan", "webpay");
  PLATFORM.moduleName("web-pay/google/persistent-subscription/google-persistent-subscription-service", "webpay");

  // webpay administration
  PLATFORM.moduleName("admin/web-pay/paypal/plans/billing-plan", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/disputes/disputes", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/disputes/dispute-service", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/sales/sales", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/sales/sales-service", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/subscriber/subscriber", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/subscriber/subscriber-service", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/webhook/webhook-event-notification", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/webhook/webhook", "webpay");
  PLATFORM.moduleName("admin/web-pay/paypal/webhook/webhook-service", "webpay");
  PLATFORM.moduleName("admin/utilities/helpers", "webpay");
  PLATFORM.moduleName("web-pay/paypal/payment-response/payment-response", "webpay");
  PLATFORM.moduleName("web-pay/paypal/redirect/paypal-redirect", "webpay");

  //test only
  PLATFORM.moduleName("home/experimental/nude-test/nude-test", "adminmain");

  /*
  aurelia.use.plugin(PLATFORM.moduleName("home/velocity-test/sequences", "main"));
  */

  // Startup Aurelia and set the appropriate root.
  await aurelia.start();
  const appSecurity = Container.instance.get(AppSecurity)
  if (!appSecurity.isAuthenticated()) {
    await aurelia.setRoot(PLATFORM.moduleName("shell/roots/public", "appstart"));
  } else {
    // The setting of other roots is done by the user root.  This is the case
    // because the roles need to be checked and the user profile isn't available
    // at this point.
    await aurelia.setRoot(PLATFORM.moduleName("shell/roots/user", "usermain"));
  }
}
