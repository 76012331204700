import { AppConfig } from "shell/app-config";
import { Container } from "aurelia-framework";
import { Router } from "aurelia-router";
import { HttpClient, json } from "aurelia-fetch-client";
import { Logger } from "services/logger";
import { ErrorHandler } from "services/error-handler";
import { ErrorDisplayType, ToastDisplay } from "./enums";
import { RecaptchaResponse } from "model/account/recaptcha-response";
import { CaptchaVerify } from "model/route-data-models";
import toastr from "toastr";

class URLData {
  queryArgs: string;
}

export class ServiceBase {
  httpClient: HttpClient;
  logger: Logger;
  errorHandler: ErrorHandler;
  appConfig: AppConfig;
  baseUrl: string;
  defaultApiEndPoint: string;
  public message: string;
  defaultToastOptions: ToastrOptions;
  fileUploadDir: string;
  httpResponse: Response;

  constructor(aApiService: string, ...rest: any[]) {
    this.httpClient = Container.instance.get(HttpClient);
    this.logger = Container.instance.get(Logger);
    this.errorHandler = Container.instance.get(ErrorHandler);
    this.appConfig = Container.instance.get(AppConfig);
    this.message = "";
    this.baseUrl = this.appConfig.baseApiUrl;
    this.fileUploadDir = this.appConfig.fileUploadDir;
    this.defaultToastOptions = this.appConfig.toastrOptions;
    this.defaultApiEndPoint = aApiService;
    this.httpResponse = undefined;
  }

  /*
  verifyResponse(response: IAPIResult, notificationType: ErrorDisplayType, optionalMesssage?: string): boolean {
    if (response.Error) {
      switch (notificationType) {
        case ErrorDisplayType.Dialog:
          break;
        case ErrorDisplayType.Toast:
          var temp = response.Error.friendlyMsg;
          toastr.error(response.Error.friendlyMsg, "", this.defaultToastOptions);
          break;
        case ErrorDisplayType.None:
          break;
      }
      return false;
    } else {
      return true;
    }
  }
  */
  displayToast(message: string, toastDisplay: ToastDisplay, autoHide: boolean = true,
    title: string = "", displayDuration:number=-1) {
    if (autoHide) {
      if (displayDuration === -1) {
        this.defaultToastOptions.timeOut = 10000;
      } else {
        this.defaultToastOptions.timeOut = displayDuration;
      }
      this.defaultToastOptions.closeButton = false;
    } else {
      this.defaultToastOptions.timeOut = 0;
      this.defaultToastOptions.closeButton = true;
    }
    switch (toastDisplay) {
      case ToastDisplay.Error:
        toastr.error(message, title, this.defaultToastOptions);
        break;
      case ToastDisplay.Success:
        toastr.success(message, title, this.defaultToastOptions);
        break;
      case ToastDisplay.Warn:
        toastr.warning(message, title, this.defaultToastOptions);
        break;
      default:
        toastr.info(message, title, this.defaultToastOptions);
        break;
    }
  }

  displayToastWithEvents(title: string, message: string,
    toastDisplay: ToastDisplay, onClick: any, onCloseClick: any,
    closeBtnHtml: string = "") {
    var options = this.defaultToastOptions;

    options.timeOut = 0;
    options.closeButton = true;
    options.onclick = onClick;
    options.onCloseClick = onCloseClick;
    switch (toastDisplay) {
      case ToastDisplay.Error:
        toastr.error(message, title, options);
        break;
      case ToastDisplay.Success:
        toastr.success(message, title, options);
        break;
      case ToastDisplay.Warn:
        toastr.warning(message, title, options);
        break;
      default:
        toastr.info(message, title, options);
        break;
    }
  }

  removeToast() {
    toastr.remove(toastr.getContainer());
  }

  getAPIUrl(request: string, aApiService?: string): string {
    if (request.substr(0, 1) === "/") {
      // remove leading slash
      request = request.substring(1);
    }

    if (aApiService) {
      return this.baseUrl + aApiService + request;
    } else {
      return this.baseUrl + this.defaultApiEndPoint + request;
    }
  }

  processError(error: any, router: Router) {
    var statusText: string, type: string;
    var status: number;
    statusText = error.statusText;
    status = error.status;
    type = error.type;
    switch (status) {
      case 400:
        this.displayToast(error.message, ToastDisplay.Error, false);
        break;
      case 401:
        router.navigateToRoute("unauthorized");
        break;
      case undefined:
        if (!navigator.onLine) {
          router.navigateToRoute("offline");
        } else {
          // server may be down
          this.displayToast("Unable able to service request.  The web server may be down.", ToastDisplay.Error, false);
        }
        break;
      default:
        if (!navigator.onLine) {
          router.navigateToRoute("offline");
        } else {
          this.displayToast(error.message, ToastDisplay.Error, false);
        }
        break;
    }
  }

  copyToClipboard(name: string, textToCopy: string) {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        this.displayToast(name + " copied to clipboard.", ToastDisplay.Info);
      })
      .catch((err: any) => {
        this.displayToast("Copy failed!.", ToastDisplay.Warn);
      })
  }

  /**
   * Retrieves available subscriptions.
   * @returns PlanList
   *  https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
   */
  parseJSONDate(date: string): Date {
    var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
    var reNoTime = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:Z|(\+|-)([\d|:]*))?$/;

    if (typeof date === "string") {
      var a = reISO.exec(date);
      if (a) {
        return new Date(date);
      }
      a = reMsAjax.exec(date);
      if (a) {
        var b = a[1].split(/[-+,.]/);
        return new Date(b[0] ? +b[0] : 0 - +b[1]);
      }
      a = reNoTime.exec(date);
      if (a) {
        return new Date(date);
      }
    }
    return undefined;
  }

  /**
   * Encodes/Encrypts query args represented as JSON object of key
   * value pairs.
   * @param data - object with key value pairs
   * @returns Promsise of string (UrlEncoded)
   */
  encodeURLData(data: object): Promise<string> {
    // encrypt the query args
    var dataToPost = new URLData();
    // double JSON conversion required to convert the JSON data to a string.
    dataToPost.queryArgs = JSON.stringify(JSON.stringify(data));

    return this.httpClient.fetch(
      this.getAPIUrl(this.appConfig.appApiRoute.encodeUrl), {
        method: "post",
        body: json(dataToPost)
      })
      .then((response: any) => (response.json()))
      .then((response: string) => {
        return response;
      })
      .catch((err: any) => (err.json())
        .then((errorResponse: any) => {
          var message: string;
          if (errorResponse.modelState) {
            Object.keys(errorResponse.modelState)
              .forEach(e => {
                message = errorResponse.modelState[e] + " ";
                //console.error(`key=${e}  value=${errorResponse.modelState[e]}`)
              });
            message = message + errorResponse.message;
          } else {
            message = errorResponse.message;
          }
          this.logger.logError(message, err, errorResponse, true);
          return errorResponse;
        })
      );
  }

  /**
   * Decodes/Decrypts URLEncode string into JSON data representing 
   * the query args as JSON object of key value pairs.
   * @param data - UrlEncoded encrypted data
   * @returns Promsise object
   */
  decodeURLData(data: string): Promise<object> {
    // decode the encrypted data.
    var dataToPost = new URLData();
    dataToPost.queryArgs = data;
    return this.httpClient.fetch(
      this.getAPIUrl(this.appConfig.appApiRoute.decodeUrl), {
        method: "post",
        body: json(dataToPost)
      })
      .then((response: any) => (response.json()))
      .then((response: string) => {
        // first conversion convert back to JSON string.
        return JSON.parse(JSON.parse(response));
      })
      .catch((err: any) => (err.json())
        .then((errorResponse: any) => {
          var message: string;
          if (errorResponse.modelState) {
            Object.keys(errorResponse.modelState)
              .forEach(e => {
                message = errorResponse.modelState[e] + " ";
                //console.error(`key=${e}  value=${errorResponse.modelState[e]}`)
              });
            message = message + errorResponse.message;
          } else {
            message = errorResponse.message;
          }
          this.logger.logError(message, err, errorResponse, true);
          return errorResponse;
        })
      );
  }

  /**
   * Each reCAPTCHA user response token is valid for two minutes, and 
   * can only be verified once to prevent replay attacks. If you need a new token, 
   * you can re-run the reCAPTCHA verification.
   *
   * After you get the response token, you need to verify it within two minutes 
   * with reCAPTCHA using the following API to ensure the token is valid.
   * @param token - user recaptcha response token
   * @returns Promsise RecaptchaResponse
   */
  recaptchSiteVerify(token: string): Promise<void | RecaptchaResponse> {
    var data = new CaptchaVerify();
    data.token = token;
    // post to Web API for verification.  The end point then posts to
    // Google to perform the verification.
    return this.httpClient.fetch(
      this.getAPIUrl(this.appConfig.appApiRoute.recaptchVerification), {
        method: "post",
        body: json(data)
      })
      .then((response: any) => (response.json()))
      .then((response: any) => {
        // first convert back to JSON string.
        var temp = JSON.parse(response.content)
        var result = new RecaptchaResponse();
        result.challengeTS = new Date(temp.challenge_ts);
        // have to use the [] access because a dash is in the 
        // variable name which is illegal. this is comming from Google.
        result.errorCodes = temp["error-codes"];
        result.hostname = temp.hostname;
        result.success = temp.success;
        return result;
      })
      .catch((err: any) => {
        var temp = err;
      });
  }
}