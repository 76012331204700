import { SendToJury, Theme, ProfileVisibility, ProfileStartupView } from "common/enums";
import { IUserOptions } from "common/interfaces";
import { NotificationMethod } from "common/notification-method";

export class UserOptions implements IUserOptions {
  public imageNotification: NotificationMethod;
  public newMessageNotification: NotificationMethod;
  public newMatchNotification: NotificationMethod;
  public newInterestNotification: NotificationMethod;

  public personalProfileSeq: number;
  public soulProfileSeq: number;
  public spiritProfileSeq: number;
  public sendToJury: SendToJury
  public theme: Theme;
  public profileVisibility: ProfileVisibility;
  public profileStartupView: ProfileStartupView;
  public matchFilter: string;

  constructor(json: string | UserOptions) {
    var temp: UserOptions;
    if (json instanceof UserOptions) {
      throw new TypeError("Invalid argument: User option contructor can only convert a JSON string.");
    }
    if (json == undefined || json == null) {
      json = "{}";
    }
    temp = JSON.parse(<string>json);
    temp.personalProfileSeq == undefined ? this.personalProfileSeq = 1 : this.personalProfileSeq = temp.personalProfileSeq;
    temp.soulProfileSeq == undefined ? this.soulProfileSeq = 1 : this.soulProfileSeq = temp.soulProfileSeq;
    temp.spiritProfileSeq == undefined ? this.spiritProfileSeq = 1 : this.spiritProfileSeq = temp.spiritProfileSeq;
    temp.imageNotification == undefined || typeof (temp.imageNotification) == "string" ? this.imageNotification = new NotificationMethod() : this.imageNotification = temp.imageNotification;
    temp.newMessageNotification == undefined || typeof (temp.newMessageNotification) == "string" ? this.newMessageNotification = new NotificationMethod() : this.newMessageNotification = temp.newMessageNotification;
    temp.newMatchNotification == undefined || typeof(temp.newMatchNotification) == "string" ? this.newMatchNotification = new NotificationMethod() : this.newMatchNotification = temp.newMatchNotification;
    temp.newInterestNotification == undefined || typeof (temp.newInterestNotification) == "string" ? this.newInterestNotification = new NotificationMethod() : this.newInterestNotification = temp.newInterestNotification;
    temp.sendToJury == undefined ? this.sendToJury = SendToJury.Ask : this.sendToJury = temp.sendToJury;
    temp.theme == undefined ? this.theme = Theme.Standard : this.theme = temp.theme;
    temp.profileVisibility == undefined ? this.profileVisibility = ProfileVisibility.Normal : this.profileVisibility = temp.profileVisibility;
    temp.profileStartupView == undefined ? this.profileStartupView = ProfileStartupView.UserDashboard : this.profileStartupView = temp.profileStartupView;
    temp.matchFilter == undefined ? this.matchFilter = undefined : this.matchFilter = temp.matchFilter;
    return this;
  }

  getSeqForPersonalContentLookup() {
    // content is an array, so we need to decrement the sequence number.
    return this.personalProfileSeq - 1;
  }

  getSeqForSoulContentLookup() {
    // content is an array, so we need to decrement the sequence number.
    return this.soulProfileSeq - 1;
  }
  getSeqForSpiritContentLookup() {
    // content is an array, so we need to decrement the sequence number.
    return this.spiritProfileSeq - 1;
  }

  setSequences(seq: number) {
    this.personalProfileSeq = seq;
    this.soulProfileSeq = seq;
    this.spiritProfileSeq = seq;
  }
}