import { UserSubscriptionHistoryBindingModel } from "model/route-data-models";

export class UserSubscriptions {
  activeSubscriptions: UserSubscriptionHistoryBindingModel[];
  expiredSubscription: UserSubscriptionHistoryBindingModel[];
  hasSubscriptions: Boolean;

  constructor(userSubscriptions: UserSubscriptionHistoryBindingModel[]) {
    this.initialize(userSubscriptions);
  }

  initialize(userSubscriptions: UserSubscriptionHistoryBindingModel[]) {
    if (Array.isArray(userSubscriptions)) {
      this.activeSubscriptions = userSubscriptions.filter(s => s.active === true);
      this.expiredSubscription = userSubscriptions.filter(s => s.active === false);
      if (userSubscriptions.length > 0) {
        this.hasSubscriptions = true;
      } else {
        this.hasSubscriptions = false;
      }
    } else {
      this.hasSubscriptions = false;
    }
  }
}