import { inject } from "aurelia-framework";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { EventSubscription } from "common/event-subscription";
import { EventMessageType } from "common/enums";

@inject(EventAggregator)
export class ExtendedEventAggreator {
  ea: EventAggregator;
  //private allowDuplicateSubscriptions: boolean;
  private subscriptions: EventSubscription[];

  constructor(ea: EventAggregator) {
    this.ea = ea;
    this.subscriptions = [];
  }

  /**
      * Publishes a message.
      * @param event The event or channel to publish to.
      * @param data The data to publish on the channel.
      */
  publish(event: string | any, data?: any): void {
    this.ea.publish(event, data);
  }

  /**
   * Get EventAggregator subscription and keep track.
   * @param message
   * @typeparam EventMessageType
   * @param callback
   * @typeparam Function
   */
  subscribe(message: EventMessageType, callback: Function, allowDuplicateSubscriptions=false) {
    var subscription;
    if (!allowDuplicateSubscriptions) {
      subscription = this.subscriptions.find(s => s.eventMessage === message);
      // prevent duplicate subscriptions
      if (subscription === undefined) {
        this.subscriptions.push(new EventSubscription(message, this.ea.subscribe(message, callback)));
        subscription = this.subscriptions[this.subscriptions.length - 1].eventSubscription;
        subscription.guid = this.subscriptions[this.subscriptions.length - 1].guid;
      }
    } else {
      this.subscriptions.push(new EventSubscription(message, this.ea.subscribe(message, callback)));
      subscription = this.subscriptions[this.subscriptions.length - 1].eventSubscription;
      subscription.guid = this.subscriptions[this.subscriptions.length - 1].guid;
    }
    return subscription;
  }

  dispose(message: EventMessageType, guid:string): void {
    var idx = this.subscriptions.findIndex(s => s.eventMessage === message
      && s.guid == guid);
    if (idx !== -1) {
      this.subscriptions[idx].eventSubscription.dispose();
      this.subscriptions.splice(idx, 1);
    }
  }

  disposeAllSubscriptions() {
    for (var i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions.pop().eventSubscription.dispose();
    }
  }

}
