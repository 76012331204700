import $ from "jquery";
import "signalr";
import { ServiceBase } from "./service-base";
import { SignalRState } from "./enums";
import { ISignalR } from "common/interfaces";
import { Describer } from "common/describer";

export class SignalRBase<T> extends ServiceBase implements ISignalR {
  protected connection: SignalR.Hub.Connection;
  protected proxy: SignalR.Hub.Proxy;
  hubUrl: string;
  spotOnLoveHubProxy: string;
  accountHubProxy: string;

  constructor(aApiService: string, ...rest: any[]) {
    super(aApiService, rest);
    this.hubUrl = this.appConfig.hubUrl;
    this.spotOnLoveHubProxy = "voteOnLove";
    this.accountHubProxy = "account";
  }

  initSignalR() {
    // wrap the SignalR startup in a promise to avoid UI blocking.
    var self = this;
    return new Promise((reslove, reject) => {
      this.connection = $.hubConnection(this.hubUrl);
      this.proxy = this.connection.createHubProxy(this.spotOnLoveHubProxy);
      this.connection.error((error: SignalR.ConnectionError) => self.connectionError(error));
      this.connection.connectionSlow(() => self.connectionSlow());
      this.connection.stateChanged((state: SignalR.StateChanged) => self.connectionStateChange(state));
      this.connection.disconnected(() => self.disconnected());
      reslove(true);
    })
  }

  /**
   * Start SignalR connection.
   */
  public startConnection() {
    if (navigator.onLine) {
      //console.log("Starting SignalR connection - %s", this.hubUrl);
      return this.connection
        .start()
        .catch((err: any) => {
          var test = err;
          this.logger.error(err.toString());
        });
    } else {
      new Promise((resolve, reject) => {
        this.logger.warn("Cannot start connection - OffLine!");
        resolve(false);
      });
    }
  }

  /**
   * Stop SignalR connection.
   */
  public stopConnection() {
    return this.connection.stop();
  }

  /**
   * Called when an connection state change occurs.
   * @param error  notification 
   */
  connectionStateChange(state: SignalR.StateChanged) {
    //console.warn(Describer.getClassName(this) + "-SignalR state: Old: " + SignalRState[state.oldState] +
    //  " New: " + SignalRState[state.newState]);
  }

  /**
   * Called when an connection error occurs.
   * @param error  notification 
   */
  connectionError(error: SignalR.ConnectionError) {
    this.logger.error("SignalR error: " + error);
  }

  /**
   * Called when an connection error occurs.
   */
  connectionSlow() {
    this.logger.warn("We are currently experiencing difficulties with the connection.");
  }

  /**
   * Client disconnected
   */
  disconnected() {
    if (this.connection.lastError) {
      this.logger.error("Disconnected. Reason: " + this.connection.lastError.message);
    }
  }

  /**
   * SignalR client end point. Implemented in descendant.
   */
  notifier<T>(data: T) {
    this.logger.error("SignalRBase: Notifer method not implemented. Implement in descendant.");
  }

  /**
   * Called when an connection error occurs.
   * @param methodName  Hub method name
   * @param data  Hub data
   */
  public sendMessageSignalR(methodName: string, data: any) {
    switch (this.proxy.connection.state) {
      case SignalRState.Disconnected:
        this.startConnection()
          .then(_ => {
            this.proxy.invoke(methodName, data);
            return Promise.resolve(true);
          })
          .catch(_ => {
            this.logger.error("Servicebase: SignalR connection start failed.")
          });
        break;
      default:
        this.proxy.invoke(methodName, data);
        return Promise.resolve(true);
    }
  }

  /**
   * Verifies the the SignalR connection is connect and if not 
   * starts the connection.
   */
  VerifyAndStartConnection() {
    if (navigator.onLine) {
      switch (this.proxy.connection.state) {
        case SignalRState.Disconnected:
          this.startConnection()
            .then(_ => {
              return Promise.resolve(true);
            })
            .catch(_ => {
              this.logger.error("Servicebase: SignalR connection start failed.")
            });
          break;
        default:
          return Promise.resolve(true);
      }
    } else {
      this.logger.warn("Cannot start connection - OffLine!");
      return Promise.resolve(false);
    }
  }
}