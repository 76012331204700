import { inject, Container } from "aurelia-framework";
import toastr from "toastr";
import { PLATFORM } from "aurelia-pal";
import { AppRouter } from "aurelia-router";
import {
  ConfigurationMode, NotificationPermissionStatus,
  RouteCategoryTitle, SideMenu, PWAInstallType
} from "common/enums";
import { ColorTheme } from "model/color-theme";
import {
  INotificationOptions, IPushNotificationOption,
  IRouteCategory, IAppVersion
} from "common/interfaces";
import { ConfigurationSetting } from "./configuration-setting";
import { PushNotificationMessage } from "common/push-notification-message";
import { SettingsViewModel } from "model/route-data-models";
import breeze from "breeze-client";
import { ClientJS } from "clientjs";
import { AccessRestraint } from "account/access-restraint";
import { PWAOptions } from "common/pwa-options";

@inject(ClientJS)
class RequiredFeatures {
  serviceWorker: boolean;
  cacheApi: boolean;
  matchMedia: boolean;
  onBeforeInstallPrompt: boolean;
  crypto: boolean;
  fetch: boolean;
  usingPolyFills: boolean;
  browser: string;
  browserVersion: string;
  clientJS: ClientJS;
  fingerprint: number;
  os: string;

  constructor(clientJS: ClientJS) {
    //fingerprint
    this.clientJS = clientJS;
    this.fingerprint = this.clientJS.getFingerprint();
    this.browser = this.clientJS.getBrowser();
    this.browserVersion = this.clientJS.getBrowserVersion();
    this.os = this.clientJS.getOS() + " " + this.clientJS.getOSVersion();

    //required features
    this.serviceWorker = "serviceWorker" in navigator ? true : false;
    this.matchMedia = "matchMedia" in window ? true : false;
    this.onBeforeInstallPrompt = "onbeforeinstallprompt" in window ? true : false;
    // For testing Safari in Chrome emulation mode the value needs to be set to false.
    //this.onBeforeInstallPrompt = false;
    this.crypto = "crypto" in window ? true : false;
    this.fetch = "fetch" in window ? true : false;
    this.cacheApi = "caches" in window ? true : false;
    this.usingPolyFills = window.spotOnLovePolyfills;
  }

  hasRequiredFeatures() {
    let isSafari = this.clientJS.isSafari()
      || this.clientJS.isMobileSafari();

    return (!this.usingPolyFills)
      || (isSafari && parseInt(this.clientJS.getBrowserMajorVersion(), 10) >= 13);
  }
}

 /** 
  * Breeze routes
  */
class AppApiBreezeRoute {
  // breeze Routes. Relative to entitymanagerprovider service name
  acceptTerms: string = "voteonlove/acceptTerms";
  acceptPrivacy: string = "voteonlove/acceptPrivacy";
  saveChanges: string = "voteonlove/savechanges";
  publicArticles: string = "voteonlove/publicarticles";
  privateArticles: string = "voteonlove/privatearticles";
  profiles: string = "voteonlove/profiles";
  profile: string = "voteonlove/getprofile";
  compatibleMatches: string = "voteonlove/compatiblematches";
  compatibleMatchesCount: string = "voteonlove/compatiblematchescount";
  conversations: string = "voteonlove/conversations";
  conversationUpdateViewed: string = "api/VoteOnLoveUtility/conversationUpdateViewed";
  conversationUpdateIgnore: string = "api/VoteOnLoveUtility/conversationUpdateIgnore";
  conversationUpdateReport: string = "api/VoteOnLoveUtility/conversationUpdateReport";
  conversationHistory = "voteonlove/conversationHistory";
  contact = "voteonlove/contact";
  notifications: string = "voteonlove/notifications";
  supplementalUserData: string = "voteonlove/supplementalUserData";

  matchHistory: string = "voteonlove/matchHistory";
  touViolation: string = "voteonlove/touViolation";
  ballot: string = "voteonlove/ballots";
  userVote: string = "voteonlove/userVotes";
  voteSummary: string = "voteonlove/voteSummary";
  getOpenBallots: string = "breeze/voteonlove/getOpenBallots";

  // lookups
  lookup: string = "voteonlove/lookups";
  categories: string = "voteonlove/categories";
  wirelessCarriers: string = "voteonlove/wirelessCarriers";
  pushTextTypes: string = "voteonlove/pushTextTypes";
  tagType: string = "voteonlove/tagTypes";
  locations: string = "voteonlove/locations";
  help: string = "voteonlove/help";
  profileTag: string = "voteonlove/profileTags";
  profileImage: string = "voteonlove/profileImages";
  tmpProfileImage: string = "voteonlove/tmpprofileImages";

  // authentication Routes
  addExternalLogin: string = "/api/account/addexternallogin";
  confirmAccount: string = "/api/account/ConfirmEmail";
  changePassword: string = "/api/account/changepassword";
  securityToken: string = "/token";
  logout: string = "/api/account/logout";
  loadTestData: string = "/api/account/loadtestdata";
  fileUpload: string = "/api/account/upload";
  fileDelete: string = "/api/account/filedelete";
  register: string = "/api/account/register";
  registerExternal: string = "/api/account/registerexternal";
  deleteaccount: string = "/api/account/deleteaccount";
  manageAccount: string = "/api/account/manageinfo";
  removeLogin: string = "/api/account/removelogin";
  setPassword: string = "/api/account/setpassword";
  lastLogin: string = "/api/account/lastlogin";
  settings: string = "/api/account/settings";
  site: string = "/";
  userInfo: string = "/api/account/userinfo";
  getUsers: string = "/api/account/getusers";
  getApiVersion: string = "/api/account/getApiVersion";
  forgotPassword: string = "/api/account/forgotpassword";
  recoverAccount: string = "/api/account/recoverAccount";
  resendMailRoute: string = "/api/account/resendconfirmationemail";
  resetPassword: string = "/api/account/resetpassword";
  sendAccountRecoveryEmail: string = "/api/account/sendAccountRecoveryEmail";
  resendAccountRecoveryEmail: string = "/api/account/resendAccountRecoveryEmail";
  // utility routes
  ballotNotificationUpdate: string = "/api/voteonloveutility/ballotnotificationUpdate";
  conversationUnmatch: string = "/api/voteonloveutility/conversationUmatch";
  decodeUrl: string = "/api/voteonloveutility/decodeData";
  encodeUrl: string = "/api/voteonloveutility/encodeData";
  getShareData: string = "/api/voteonloveutility/getsharedata";
  getShareURLhash: string = "/api/voteonloveutility/shareurlhash";
  getCompatibleInformation: string = "/api/voteonloveutility/compatibleInformation";
  getUpdatedProfile: string = "/api/voteonloveutility/getUpdatedProfile";
  getEncryptedData: string = "/api/voteonloveutility/getEncryptedData";
  notificationUpdate: string = "/api/voteonloveutility/notificationUpdate";
  setanalytics: string = "/api/voteonloveutility/setanalytics";
  getCapatcha: string = "/api/voteonloveutility/generateCaptcha";
  sendEmail: string = "/api/voteonloveutility/sendEmail";
  // Paypal payment routes single payment, ecommerce payment, billing plan, 
  // subscription plan, subscribers, webhooks, and webexperience profile moved to
  // PayPalPayment class to allow supporting multiple payment processors.

  // Google Playbilling routes
  // GET
  getGooglePurchaseDetails: string = "api/google/getPurchaseDetails";
  getGoogleSubscriptionDetails: string = "api/google/getSubscriptionDetails";
  getGoogleSubscriptionV2Details: string = "api/google/getSubscriptionV2Details";
  getGoogleSubscriptionList: string = "api/google/getSubscriptionList";
  getGoogleSKUList: string = "api/google/getSKUList";
  getGoogleSubscriptionV2: string = "api/google/getSubscriptionGoogle";
  verifySubscription: string = "api/google/verifySubscription";
  // POST
  acknowledgeSubscriptionAndGrantEntilements: string = "api/google/acknowledgeSubscriptionAndGrantEntilements";
  saveGooleSubscription: string = "api/google/saveSubscription";
  saveGooleSubscriptionV2: string = "api/google/saveSubscriptionV2";
  saveGoolePurhcase: string = "api/google/savePurchase";
  testAcknowledgeSubscriptionAndGrantEntilements: string = "api/google/testAcknowledgeSubscriptionAndGrantEntilements";

  //third party
  recaptchVerification: string = "/api/account/siteverify";

  // site page urls
  unauthorized: string = "account/unauthorized";
  login: string = "account/login";
  signUp: string = "account/login";
  chatUrl: string = "chat";
  matchmaking: string = "matches";
  vote: string = "vote";
  materialTest: string = "home/materialtest";
  velocityTest: string = "home/velocitytest";
  validationTest: string = "home/validationtest";
}


@inject(RequiredFeatures, ConfigurationSetting, AccessRestraint, PWAOptions)
export class AppConfig {
  // change this value to change configuration mode.
  public readonly configurationMode: ConfigurationMode = ConfigurationMode.Staging;
  public readonly breezeServiceName: string;
  public readonly baseApiUrl: string;
  public readonly hubUrl: string;
  public readonly fileUploadDir: string;
  public readonly toastrOptions: ToastrOptions;
  public readonly systemPrompt: string;
  public readonly defaultTheme: ColorTheme;
  public readonly appApiRoute: AppApiBreezeRoute;
  public readonly badge: string;
  public readonly imageBaseDir: string;
  public readonly maxPersonalProileSeq: number;
  public readonly byPassSendingEmail: boolean;
  public readonly retrievalPageSize: number;
  public readonly isSafariBrowser: boolean;
  public readonly isChromeBrowser: boolean;
  public readonly isIEBrowser: boolean;
  public readonly isModeFullscreen: boolean;
  public readonly requiredFeatures: RequiredFeatures;
  public maxMatchesPerDay: number;
  public maxInterestedMatchesPerDay: number;
  public apiVersion: string;
  public appVersion: IAppVersion;
  public pwaOptions: PWAOptions;

  public icon: string;
  public sw: ServiceWorkerRegistration; //service worker
  public notificationOptions: INotificationOptions;
  public readonly configuration: ConfigurationSetting;
  public matchfetchStrategy: breeze.FetchStrategySymbol;
  public swMessageEventAttached: boolean;
  public payPalWindow: Window;
  public apiSettings: SettingsViewModel;
  public accessRestraint: AccessRestraint;

  constructor(requiredFeatures: RequiredFeatures, configurationSetting: ConfigurationSetting,
    accessRestraint: AccessRestraint, pwaOptions: PWAOptions) {
    this.systemPrompt = "SpotOnLove";
    this.appApiRoute = Container.instance.get(AppApiBreezeRoute);
    this.badge = "/assets/images/android/android-chrome-192x192.png";
    this.icon = "/assets/images/android/android-chrome-192x192.png";
    this.sw = undefined;
//    this.configuration = new ConfigurationSetting();
    this.configuration = configurationSetting;
    this.swMessageEventAttached = false;
    this.apiSettings = undefined;
    this.retrievalPageSize = 50;
    this.maxMatchesPerDay = 14;
    this.maxInterestedMatchesPerDay = 7;
    this.apiVersion = "";
    this.appVersion = undefined;
    //this.pwaInstallType = PWAInstallType.None;
    //this.requiredFeatures = new RequiredFeatures();
    this.requiredFeatures = requiredFeatures;
    this.accessRestraint = accessRestraint;
    this.pwaOptions = pwaOptions;
    this.accessRestraint.id = Math.floor(Math.random() * 100);

    // configuration settings
    this.maxPersonalProileSeq = 5;
    this.imageBaseDir = this.configuration.imageBaseDir;
    this.breezeServiceName = this.configuration.breezeServiceName;
    this.baseApiUrl = this.configuration.baseApiUrl;
    this.hubUrl = this.configuration.hubUrl;
    this.fileUploadDir = this.configuration.fileUploadDir;
    this.byPassSendingEmail = this.configuration.byPassSendingEmail;
    this.matchfetchStrategy = breeze.FetchStrategy.FromServer;
    // this setting in conjuction with the API NOMAIL preprocessor
    // directive prevent the email from being sent and render on 
    // the page sending email.  For production this should be set
    // to false.
    this.byPassSendingEmail = true;

    //PWA configuration
    this.isChromeBrowser = this.requiredFeatures.clientJS.isChrome();
    this.isIEBrowser = this.requiredFeatures.clientJS.isIE() || this.requiredFeatures.browser == "Edge";
    this.isSafariBrowser = this.requiredFeatures.clientJS.isSafari()
      || this.requiredFeatures.clientJS.isMobileSafari();

    // Navigator.standalone Non-standard
    // Returns a boolean indicating whether the browser is running in standalone mode.
    // Available on Apple's iOS Safari only.
    this.isModeFullscreen = ("standalone" in window.navigator
      && <any>(window.navigator).standalone === true)
      || (window.matchMedia("(display-mode: standalone)").matches);

    this.toastrOptions = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: "toast-top-left",
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    // theme based on yellow text: https://www.colorhexa.com/ffff00
    this.defaultTheme = new ColorTheme();
    this.defaultTheme.primaryColor = "linear-gradient(to top, rgba(0,0,110,1), rgba(0,0,0,1))";
    this.defaultTheme.secondaryColor = "linear-gradient(to top, rgba(0,0,200,1), rgba(0,0,0,1))";
    this.defaultTheme.accentColor = "linear-gradient(to top, rgba(0,0,200,1), rgba(0,0,0,1))";
    this.defaultTheme.errorColor = "linear-gradient(to bottom, rgba(200,0,200,1), rgba(0,0,0,1))";
    this.defaultTheme.successColor = "linear-gradient(to top, rgba(0,200,0,1), rgba(0,0,0,1))";
    this.defaultTheme.linkColor = "linear-gradient(to top, rgba(0,0,130,1), rgba(100,100,100,1))";
    this.defaultTheme.primaryTextColor = "#ffffff";
    this.defaultTheme.secondaryTextColor = "#ffffff";
    this.defaultTheme.accentTextColor = "#ffffff";
    this.defaultTheme.errorTextColor = "#ff0000";
    this.defaultTheme.successTextColor = "#80ff00";
    this.defaultTheme.linkTextColor = "#0000ff";

    this.notificationOptions = {
      //Visual Options
      body: "",
      icon: "/assets/images/android/android-chrome-192x192.png",
      image: "",
      badge: "/assets/images/android/android-chrome-192x192.png",
      vibrate: [],
      sound: "",
      dir: <NotificationDirection>"auto",
      //Behavioural Options
      tag: "",
      data: null,
      requireInteraction: false,
      renotify: false,
      silent: false,
      //Both Visual & Behavioural Options
      actions: [],
      //Information Option. No visual affect
      timestamp: Math.floor(Date.now())
    }
    //this.defaultTheme = new ColorTheme("#039be5",
    //"#ffff00", "#ffff00", "#ffff00", "#ffff00", "#ffff00", "#ffff00");
  }

  /**
   * Use with native notification to get the current notification permission status.
   */
  getNotificationPermission(): NotificationPermissionStatus {
    var currentStatus: NotificationPermissionStatus;
    switch (Notification.permission) {
      case NotificationPermissionStatus.Ask:
        currentStatus = NotificationPermissionStatus.Ask;
        break;
      case NotificationPermissionStatus.Allow:
        currentStatus = NotificationPermissionStatus.Allow;
        break;
      case NotificationPermissionStatus.Block:
        currentStatus = NotificationPermissionStatus.Block;
        break;
      default:
        currentStatus = NotificationPermissionStatus.Undefined;
    }
    return currentStatus;
  }

  /**
   * Shows a notification using the service working notification API.
   * @param title
   * @param option
   */
  showNotification(title: string, option: IPushNotificationOption) {
    if (!this.isSafariBrowser) {
      if (Notification.permission === "granted") {
        this.sw.showNotification(title, option);

        //  {
        //  //body: message,
        //  badge: "/assets/images/android/android-chrome-192x192.png",
        //  icon: "/assets/images/android/android-chrome-192x192.png"
        //});
        return;
      }

      // ask for permission.
      if (Notification.permission !== "denied") {
        Notification.requestPermission()
          .then((p: NotificationPermission) => {
            if (p === "granted") {
              this.sw.showNotification(title, option);
            }
          });
      }
    } else {
      // IOS does support notifications.
      toastr.info(option.body, title, this.toastrOptions);
    }
  }

  /**
   * Check the if the PWA install prompt can be show to the user. The first
   * check is for the case when the PWA is already installed, the second is
   * for Android, and the third is for Safari.
   */
  allowPWAInstall() {
    return !this.isModeFullscreen
      && ((this.pwaOptions.hasBeforeInstallPrompt && this.pwaOptions.deferredPrompt != undefined)
      || (!this.pwaOptions.hasBeforeInstallPrompt && this.isSafariBrowser));
  }

  /** 
   * Add the add to home screen route allowing the user to install the PWA.
   */
  addAppInstallRoute(router: AppRouter, action: string) {
    let routeAdded = false;
    switch (action) {
      case "add":
        //If the browser support the before install prompt wait for to be fired; otherwise
        //add the route.
        if (this.allowPWAInstall()) {
          if (router.routes.findIndex(r => r.name == "user-install") == -1) {
            let dynamic = <IRouteCategory>{
              title: RouteCategoryTitle.Dynamic,
              iconClass: "fas fa-asterisk"
            };

            router.addRoute(
              {
                route: "user/install",
                moduleId: PLATFORM.moduleName("user/app-install/app-install", "usermain"),
                name: "user-install",
                title: "App Install",
                nav: true,
                hash: "#user/install",
                layoutViewModel: PLATFORM.moduleName("shell/layouts/user", "usermain"),
                settings: {
                  authorize: ["User"], confirmed: true, iconClass: "fas fa-caret-square-right",
                  sideNav: SideMenu.ModalAndSide, category: dynamic, share: false, dynamicLayout: false
                }
              }
            );
            router.refreshNavigation();
            routeAdded = true;
          }
        }
        break;
      case "delete":
        let indexToBeRemoved = router.routes.findIndex(r => r.name == "user-install");
        if (indexToBeRemoved >= 0) {
          router.routes.splice(indexToBeRemoved, 1);
        }
        indexToBeRemoved = router.navigation.findIndex(r => r.config.name == "user-install");
        if (indexToBeRemoved >= 0) {
          router.navigation.splice(indexToBeRemoved, 1);
        }
        router.refreshNavigation();
        routeAdded = true;
        break;
      default:
        throw new Error("addAppInstallRoute called with invalid action parameter.");
        break;
    }
    return routeAdded;

  }

  /*
  getBreezeServiceName() {
    return this.breezeServiceName;
  }

  getBaseUrl() {
    return this.baseApiUrl;
  }

  getFileUploadDirectory() {
    return this.fileUploadDir;
  }

  getDefaultToastOptions() {
    return this.toastrOptions;
  }
  */
}
