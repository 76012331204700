/**
  *  Authentication module.
  *  All the interactions with users should start from this module.
  *  This version is a port of the original to Typescript, and 
  *  is implemented as a singleton, which is the way the original
  *  was constructed.
  * [[RouteConfig]] 
  * [[Logger]] 
  * [[Utils]] 
  * [[DialogService]] 
  * [[Router]] 
  * [[ExtendedEventAggreator]]
  */

// import system = require("durandal/system");
import { Container, TaskQueue } from "aurelia-framework";
import { HttpClient, Interceptor, HttpClientConfiguration, json } from "aurelia-fetch-client";
import { AppConfig } from "shell/app-config";
import { Logger } from "services/logger";
import { SignalRBase } from "common/signalr-base";
import { UserAccount } from "model/account/user-account";
import { Utils } from "services/utils";
import { Profile } from "model/d4c/profile";
import { DialogService, DialogOpenPromise, DialogOpenResult, DialogCancelResult } from "aurelia-dialog";
import { Router, NavigationInstruction } from "aurelia-router";
import { AppNotificationModel } from "model/app-notification-model";
import { ChatMessageType, EventMessageType, CacheKey, UserRoles, ToastDisplay } from "../common/enums";
import { CacheService } from "services/cache-service";
import { DialogOutput } from "common/dialog-output";
import { SessionState } from "common/session-state";
import { ConfirmDialog } from "resources/dialogs/confirm/confirm-dialog";
import { ExtendedEventAggreator } from "services/extended-eventaggreator";
import { IQueryParam } from "common/interfaces";
import { SettingsViewModel } from "model/route-data-models";
import { RouteData } from "account/unauthorized/route-data";

export class QueryParam implements IQueryParam {
  [key: string]: string;
}
class ReloadRoute {
  route: string;
  queryParams: QueryParam;
  constructor() {
    this.route = "";
    this.queryParams = undefined;
  }
}

export class AppSecurity extends SignalRBase<AppNotificationModel> {
  "use strict";
  // properties
  cacheService: CacheService;
  appConfig: AppConfig;
  logger: Logger;
  userInfo: UserAccount;
  utils: Utils;
  router: Router;
  returnUrl: string;
  persistToken: boolean;
  copyRightYear: string;
  wizardRoute: string;
  dialogService: DialogService;
  sessionState: SessionState;
  private ea: ExtendedEventAggreator;
  payPalToken: string;
  reloadRoute: ReloadRoute;
  private task: TaskQueue
  private unauthorizedRouteData: RouteData;

  constructor(...rest: any[]) {
    super("", rest);
    this.cacheService = Container.instance.get(CacheService);
    this.appConfig = Container.instance.get(AppConfig);
    this.logger = Container.instance.get(Logger);
    this.utils = Container.instance.get(Utils);
    this.dialogService = Container.instance.get(DialogService);
    this.router = Container.instance.get(Router);
    this.ea = Container.instance.get(ExtendedEventAggreator);
    this.task = Container.instance.get(TaskQueue);
    this.unauthorizedRouteData = Container.instance.get(RouteData)

    this.returnUrl = this.appConfig.appApiRoute.site;
    this.persistToken = false;
    var tempDate = new Date();
    this.copyRightYear = tempDate.getFullYear().toString();
    // used to route from required pages, i.e., terms, privacy, profile.
    this.wizardRoute = "";
    this.payPalToken = "";
    this.reloadRoute = new ReloadRoute();

    this.sessionState = this.cacheService.retrieve(CacheKey.SessionState);
    if (this.sessionState == undefined) {
      this.sessionState = new SessionState();
      this.clearAccessToken();
    }

    this.userInfo = undefined;

    // signalR
    var self = this;
    this.initSignalR()
      .then((result) => {
        this.proxy.on("appNotificationMessage", (data: AppNotificationModel) => self.notifier(data));
      })
  }

  setReloadRoute(i: NavigationInstruction) {
    if (this.reloadRoute.route.indexOf("paymentresponse") === -1) {
      // the payment response route will remain unless cleared
      this.reloadRoute.route = i.fragment;
      this.reloadRoute.queryParams = i.queryParams;
      if (this.reloadRoute.route.indexOf("paymentresponse") !== -1) {
        //console.groupCollapsed("paymentresponse");
        //console.log("URL:" + i.fragment);
        //console.log("Query:" + i.queryString);
        //console.groupEnd();
      }
    }
  }

  /**
   * Publishes notification
   * @param data
   * @typeparam AppNotificationModel
   */
  notifier<AppNotificationModel>(data) {
    this.ea.publish(EventMessageType.AppMessageReceived, data);
  }

  /**
   * Verifies user is authenticated
   * @returns True/False
   */
  isAuthenticated(): boolean {
    let accessToken = this.cacheService.retrieve(CacheKey.JWT);
    if (accessToken) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Authorization header for include in requests
   * @returns request object with authorizatio header
   */
  get tokenInterceptor(): Interceptor {
    var self = this;
    return {
      request(request: any) {
        let accessToken = self.cacheService.retrieve(CacheKey.JWT);
        if (accessToken) {
          request.headers.append("authorization", `bearer ${accessToken}`);
          request.withCredentials = true;
        }
        return request;
      },
      response(response: any) {
        switch (response.status) {
          case 202:
            // see service worker fetch listener (swwbsrc.ts)
            if (response.statusText == "Server error - service worker fall back response.") {
              self.displayToast("Please wait - contacting the server. If you don't get a response in 10 seconds restart the application.", ToastDisplay.Error, true);
            }
            break;
          case 401:
            self.task.queueTask(() => {
              let unauthorizedDetails = self.router.routes.find(x => x.name === "account-unauthorized");
              // don't save the history entries because we need to element to return in retieval
              self.unauthorizedRouteData.httpStatus = 401;
              self.unauthorizedRouteData.URL = response.url;
              unauthorizedDetails.settings.routeData = self.unauthorizedRouteData;
              self.router.navigateToRoute("account-unauthorized");
            });
            break;
          default:
            break;
        }
        //        console.log(`Received ${response.status} ${response.url}`);
        return response; // you can return a modified Response
      },
      responseError(error: any, request?: Request, httpClient?: HttpClient) {
        if (!(error instanceof Response)) {
          if (error.message === "Failed to fetch") {
            self.displayToast("The data service is temporarily unavailable. Please try back later.", ToastDisplay.Error, true);
          }
        }
        return error;
      }
    };
  };

  /**
   * Get query string hash fragment.
   */
  getFragment(): Object {
    if (window.location.hash.indexOf("#") === 0) {
      return this.parseQueryString(window.location.hash.substr(1));
    } else {
      return {};
    }
  };

  /**
   * Parses query string parameters.
   * @param queryString
   * @returns parameter object list
   */
  parseQueryString(queryString: string): Object {
    var data = {},
      pairs, pair, separatorIndex, escapedKey, escapedValue, key, value;

    if (queryString === null) {
      return data;
    }

    pairs = queryString.split("&");

    for (var i = 0; i < pairs.length; i++) {
      pair = pairs[i];
      separatorIndex = pair.indexOf("=");

      if (separatorIndex === -1) {
        escapedKey = pair;
        escapedValue = null;
      } else {
        escapedKey = pair.substr(0, separatorIndex);
        escapedValue = pair.substr(separatorIndex + 1);
      }

      key = decodeURIComponent(escapedKey);
      value = decodeURIComponent(escapedValue);

      data[key] = value;
    }
    return data;
  };

  // 
  /**
   * clear stored access tokens from local and session storage
   */
  clearAccessToken(): void {
    this.cacheService.remove(CacheKey.JWT);
    this.cacheService.remove(CacheKey.SessionState);
    this.cacheService.remove(CacheKey.LastLoginDate);
    this.cacheService.remove(CacheKey.ExternalToken);
    this.cacheService.remove(CacheKey.LoginURL);
    this.cacheService.remove(CacheKey.UserInformation);
    this.cacheService.remove(CacheKey.Theme);
  };

  /**
   * set access tokens in local or session storage
   * @param accessToken
   * @param persistent
   */
  setAccessToken(accessToken: string, persistent: boolean): void {
    this.cacheService.store(CacheKey.JWT, accessToken, persistent);
    // If the login page is loaded directly then the public root with
    // only have set the reject responses interceptor.
    if (this.httpClient.interceptors.length == 1) {
      if (this.httpClient.interceptors[0].request.length != 1) {
        this.httpClient.interceptors.push(this.tokenInterceptor);
      }
    }
    this.updateLastLogin(persistent);
  };

  /**
   * set the external acess token - social media
   * @param accessToken
   * @param persistent
   */
  setExternalAccessToken(accessToken: string, persistent: boolean): void {
    this.cacheService.store(CacheKey.ExternalToken, accessToken, persistent);
  };

  /**
   * update the local and last login profile date.
   * @param persistent
   */
  updateLastLogin(persistent: boolean) {
    var lastLoginDate = new Date();
    this.cacheService.store(CacheKey.LastLoginDate, lastLoginDate, persistent);
    // set last login date on user profile.
    this.httpClient.fetch(
      this.getAPIUrl(this.appConfig.appApiRoute.lastLogin), {
        method: "post",
        body: json({})
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        var temp = json(err);
      });
  }

  /**
   * retrieve public accessible API settings. 
   */
  getApiSettings() {
    if (this.hasRole("ServiceClerk")) {
      if (this.appConfig.apiSettings === undefined) {
        this.httpClient.fetch(
          this.getAPIUrl(this.appConfig.appApiRoute.settings), {
            method: "get"
          })
          .then((response: any) => (response.json()))
          .then((data: SettingsViewModel) => {
            this.appConfig.apiSettings = data;
            return data;
          })
          .catch((err: any) => (err.json())
            .then((errorResponse: any) => {
              var message: string;
              if (errorResponse.modelState) {
                Object.keys(errorResponse.modelState)
                  .forEach(e => {
                    message = errorResponse.modelState[e] + " ";
                    //console.error(`key=${e}  value=${errorResponse.modelState[e]}`)
                  });
                message = message + errorResponse.message;
              } else {
                message = errorResponse.message;
              }
              this.logger.logError(message, err, errorResponse, false);
              return errorResponse;
            })
          );
      }
    } else {
      this.appConfig.apiSettings = new SettingsViewModel();
    }
  }

  getAccessToken() {
    return this.cacheService.retrieve(CacheKey.JWT);
  }

  getExternalAccessToken() {
    return this.cacheService.retrieve(CacheKey.ExternalToken);
  }

  getDashBoardRoute() {
    return "user-dashboard";
  }

  /**
   * verify the returned state match with the stored one
   * @param fragment
   */
  verifyStateMatch(fragment: any): void {
    var state;
    if (typeof (fragment.access_token) !== "undefined") {
      state = this.cacheService.retrieve(CacheKey.State);
      this.cacheService.remove(CacheKey.State);

      if (state === null || fragment.state !== state) {
        fragment.error = "invalid_state";
      }
    }
  };

  /**
   * cleanup location fragment
   */
  cleanUpLocation(): void {
    window.location.hash = "";

    if (history && typeof (history.pushState) !== "undefined") {
      history.pushState("", document.title, location.pathname);
    }
  };

  /**
   * archive session storage to local
   */
  archiveSessionStorageToLocalStorage(): Object {
    return this.cacheService.archiveSessionStorageToLocalStorage();
  };

  /**
   * restore session storage from local
   */
  restoreSessionStorageFromLocalStorage(): void {
    this.cacheService.restoreSessionStorageFromLocalStorage();
  };

  /**
   * show the account warning
   */
  bindResendEmail(): void {
    var self = this;
    $(document).off("click", "#sendConfirmationMail");
    $(document).on("click", "#sendConfirmationMail", function (event: any) {
      self.sendConfirmationMail()
        .then((data: any) => {
          self.logger.logSuccess("Email sent. Please check your inbox and confirm your account", data, null, true);
        }).catch((err: any) => {
          self.logger.logError("", err, null, true);
        });
    });
  }
  // todo: remove.
  // send confirmation mail
  sendConfirmationMail(): any {
    return this.httpClient.fetch(
      this.getAPIUrl(this.appConfig.appApiRoute.resendMailRoute), {
        method: "post"
        //      headers: this.getSecurityHeaders()
      });
  };

  /**
   * Set the authentication info. Look in storage for stored info
   * @param userName
   * @param roles
   * @param accessToken
   * @param persistent
   */
  setAuthInfo(userName: string, roleList: string,
    isEmailConfirmed: boolean, accessToken?: string, persistent?: boolean): void {
    var roles: string[];

    if (accessToken) {
      this.setAccessToken(accessToken, persistent);
    }

    this.sessionState.roleList = roleList;
    this.sessionState.userName = userName;
    if (typeof (roleList) === "string") {
      if (roleList.indexOf(",") === -1) {
        roles = [];
        roles.push(roleList);
      } else {
        roles = roleList.split(",");
      }
    }
    if (this.userInfo === undefined) {
      this.userInfo = new UserAccount("", [], false, false);
      // negative values used to initialize to default values.
      this.userInfo.updateRemainingActions(this.appConfig.maxMatchesPerDay,
        -this.appConfig.maxInterestedMatchesPerDay);
    }

    this.userInfo.userName = userName;
    this.userInfo.roles = roles;
    this.userInfo.isEmailConfirmed = isEmailConfirmed;
    this.userInfo.rememberMe = persistent;
    this.getApiSettings();
  };

  /**
   * Save user session connection information.
   */
  saveSessionState() {
    this.sessionState.isEmailConfirmed = this.userInfo.isEmailConfirmed;
    this.sessionState.rememberMe = this.userInfo.rememberMe;
    this.sessionState.userAvatar = this.userInfo.userAvatar.fileName;
    this.sessionState.userId = this.userInfo.userId;
    this.sessionState.externalId = this.userInfo.externalId;
    this.cacheService.store(CacheKey.SessionState, this.sessionState, this.sessionState.rememberMe);
  }

  /**
   * Restore user session connection information.
   */
  restoreSessionState() {
    this.sessionState = this.cacheService.retrieve(CacheKey.SessionState);
    if (this.sessionState == undefined) {
      this.sessionState = new SessionState();
      return false;
    } else {
      this.setAuthInfo(this.sessionState.userName, this.sessionState.roleList,
        this.sessionState.isEmailConfirmed,
        this.cacheService.retrieve(CacheKey.JWT), this.sessionState.rememberMe);
      this.userInfo.userId = this.sessionState.userId;
      this.userInfo.externalId = this.sessionState.externalId;
      // is the last login stale?
      if (this.cacheService.retrieve(CacheKey.LastLoginDate) !== undefined) {
        var lastLoginDate = Date.parse(this.cacheService.retrieve(CacheKey.LastLoginDate));
        var days: number, currentDate: Date, persistent: boolean;
        persistent = this.cacheService.isPersistent(CacheKey.LastLoginDate);
        currentDate = new Date();
        days = (currentDate.getTime() - lastLoginDate) / 24 / 60 / 60 / 1000;
        if (days >= 1) {
          this.updateLastLogin(persistent);
        }
      }
      return true;
    }
  }

  /**
   * Send notification message via SignalR
   */
  public sendMessage(notification: AppNotificationModel) {
    // notify all other users of login.
    return this.sendMessageSignalR("SendAppNotification", notification);
  }

  /**
   * Remove authentication info
   */
  clearAuthInfo(): void {
    var self = this;
    this.clearAccessToken();
    this.userInfo = undefined;
  };

  /**
   * Check if user belongs to the role
   * @param role string | string[]
   */
  hasRole(role: string | string[]) {
    if (this.userInfo) {
      if (Array.isArray(role)) {
        for (var i = 0; i < role.length; i++) {
          if (this.userInfo.roles.includes(role[i])) {
            return true;
          }
        }

      } else {
        return this.userInfo.roles.includes(role);
      }
    }
    return false;
  }

  /**
   * Get authenticated user info
   * @param accessToken
   */
  getUserInfo(accessToken?: string): any {
    var headers;

    if (typeof (accessToken) !== "undefined") {
      this.setAccessToken(accessToken, this.persistToken);
    };

    return this.httpClient.fetch(
      this.getAPIUrl(this.appConfig.appApiRoute.userInfo), {
        method: "get",
        cache: "no-cache"
      });
  };

  // login handeled by login-service.

  /**
   * Logout the user
   */
  logout(showDialog = true) {
    if (showDialog) {
      return this.OpenConfirmDialog("Logout", "Are you sure you want to logout?")
        .whenClosed((response: any) => {
          if (!response.wasCancelled) {
            var notification = new AppNotificationModel(this.userInfo.getUserProfile().userId,
              this.userInfo.userName,
              "", ChatMessageType.Logout, "Logout");
            var self = this;
            this.clearAccessToken();
            this.clearAuthInfo();
            // dispose all subscriptions
            this.ea.disposeAllSubscriptions();

            return new Promise((resolve, reject) => {
              this.startConnection()
                .then(_ => {
                  // notify other user with SignalR
                  this.sendMessage(notification);
                  this.stopConnection();
                  // force app to reload clearing session.
                  resolve(response);
                })
                .catch(_ => {
                  this.stopConnection();
                  resolve(response);
                });
            });
          } else {
            return response;
          }
        })
        .catch((err: any) => {
          var temp = err;
        })
    } else {
      var notification = new AppNotificationModel(this.userInfo.getUserProfile().userId,
        this.userInfo.userName,
        "", ChatMessageType.Logout, "Logout");
      var self = this;
      this.clearAccessToken();
      this.clearAuthInfo();
      // dispose all subscriptions
      this.ea.disposeAllSubscriptions();

      return new Promise((resolve, reject) => {
        this.startConnection()
          .then(_ => {
            // notify other user with SignalR
            this.sendMessage(notification);
            this.stopConnection();
            // force app to reload clearing session.
            resolve(true);
          })
          .catch(_ => {
            this.stopConnection();
            resolve(false);
          });
      });
    }
  };

  /**
   * Open the custom confirmation dialog
   * @param aTitle
   * @param aMessage
   */
  OpenConfirmDialog(aTitle: string, aMessage: string):
    DialogOpenPromise<DialogOpenResult | DialogCancelResult> {
    return this.dialogService.open({
      viewModel: ConfirmDialog, model: {
        title: aTitle,
        message: aMessage,
        yesButtonText: "Proceed",
        noButtonText: "Cancel"
      }
    });
  }

  /**
   * Move to next step in wizard navigation chain.
   * @param aProfile
   */
  wizardNextStep(aProfile: Profile): void {
    this.wizardSetRoute(aProfile);
    if (this.wizardGetRoute() !== "") {
      // goto next step
      this.router.navigate(this.wizardGetRoute());
    }
  }

  /**
   * Get next route in the wizard navigation chain.
   * @returns route 
   */
  wizardGetRoute() {
    return this.wizardRoute;
  }

  /**
   * Set next route in the wizard navigation chain.
   * @param aProfile
   */
  wizardSetRoute(aProfile: Profile): void {
    // has the user accepted the terms.
    if (!this.utils.hasAcceptedTerms(aProfile)) {
      var targetRoute = this.router.routes.find(r => r.name === "home-terms");
      this.wizardRoute = <string>targetRoute.route;
    } else {
      // has the user accepted the privacy statement
      if (!this.utils.hasAcceptedPrivacyTerms(aProfile)) {
        // enable this to force reading of privacy
        this.wizardRoute = "privacy";
      } else {
        // has the user completed their profile
        if (!this.utils.hasCompleteProfile(aProfile)) {
          this.wizardRoute = "user/editProfile";
        } else {
          this.wizardRoute = "home";
        }
      }
    }
  };


  /**
   * Helper method to open edit profile dialog.
   * @param editProfileViewModel
   * @returns Promise<DialogOutput>
   */
  editProfile(editProfileViewModel: any): Promise<DialogOutput> {
    return this.OpenEditProfileDialog(editProfileViewModel);
  };

  /**
   * Open the edit profile dialog.
   * @param aViewModel
   * @returns Promise<DialogOutput>
   */
  OpenEditProfileDialog(aViewModel: any) {
    // open profile edit dialog.
    return this.dialogService.open({
      viewModel: aViewModel, model: this.userInfo.getUserProfile()
    })
      .whenClosed((response: any) => {
        if (!response.wasCancelled) {
          return response.output;
        } else {
          return response.output;
        }
      });
  }

  /**
   * Helper method to navigate to named route.
   * @param routeName
   */
  navigateToRoute(routeName: string) {
    this.router.navigateToRoute(routeName);
  }
}
