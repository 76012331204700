// class representing user information
import { Profile } from "model/d4c/profile";
import { ProfileImage } from "model/d4c/profile-image";
import { UserOptions } from "model/d4c/user-options";
import { LikesDislike } from "common/helpers";
import { ProfileLikeType } from "common/enums";
import { UserSubscriptions } from "model/store/user-subscriptions";
import { UserSubscriptionHistoryBindingModel } from "model/route-data-models";
import { UserRoles } from "common/enums";

export class UserAccount {
  userName: string;
  roles: string[];
  isEmailConfirmed: boolean;
  userId: string;
  externalId: string;
  userAvatar: ProfileImage;
  rememberMe: boolean;
  appReloaded: boolean;
  recentNotifications: number;
  hasActiveSubscription: boolean;
  remainingMatches: number;
  remainingInterestedMatches: number;
  userSubscriptions: UserSubscriptions;
  private profile: Profile;

  constructor(name: string, roles: string[], isEmailConfirmed: boolean, rememberMe: boolean) {
    this.userName = name;
    this.roles = roles;
    this.isEmailConfirmed = isEmailConfirmed;
    this.userId = "";
    this.externalId = "";
    this.profile = null;
    this.rememberMe = rememberMe;
    this.appReloaded = false;
    this.userAvatar = undefined;
    this.recentNotifications = 0;
    this.hasActiveSubscription = false;
    this.userSubscriptions = undefined;
    this.remainingMatches = 0;
    this.remainingInterestedMatches = 0;
  };

  isAdmin() {
    return this.hasRole(UserRoles.Administrator);
  }

  isServiceClerk() {
    return this.hasRole(UserRoles.ServiceClerk);
  }

  isRegularUser() {
    return !this.hasRole(UserRoles.ServiceClerk)
      && !this.hasRole(UserRoles.Administrator)
      && this.hasRole(UserRoles.User);
  }

  isTestAccount() {
    return this.userName.indexOf(".test") !== -1 ? true : false;
  }

  hasRole(role: string | string[]) {
    if (Array.isArray(role)) {
      for (var i = 0; i < role.length; i++) {
        if (this.roles.includes(role[i])) {
          return true;
        }
      }

    } else {
      return this.roles.includes(role);
    }
    return false;
  }

  setUserProfile(profile: Profile) {
    this.profile = profile;
    this.userId = profile.userId;
    this.externalId = profile.externalId;
    this.userAvatar = profile.profileImages.find(i => i.preferred == true);
    if (this.userAvatar === undefined) {
      this.userAvatar = new ProfileImage(profile, "Unknown", "unknown.jpg", -100);
    }
  }

  setUserSubscriptions(subscriptions: UserSubscriptionHistoryBindingModel[]) {
    if (Array.isArray(subscriptions)) {
      if (this.userSubscriptions == undefined) {
        this.userSubscriptions = new UserSubscriptions(subscriptions);
      } else {
        this.userSubscriptions.initialize(subscriptions);
      }
      // allowed matches per day for non paying users.
      if (this.userSubscriptions.activeSubscriptions.length > 0) {
        this.hasActiveSubscription = true;
      }
      // administrators and service clerks don't reqire subscription
      if (this.isAdmin() || this.isServiceClerk()) {
        this.hasActiveSubscription = true;
      }
    } else {
      this.hasActiveSubscription = false;
    }
  }

  updateRemainingActions(matchesViewed: number, interestedInExecuted: number, setToValue = false) {
    if (setToValue) {
      this.remainingMatches = matchesViewed;
      this.remainingInterestedMatches = interestedInExecuted;
    } else {
      this.remainingMatches = this.remainingMatches - matchesViewed;
      this.remainingInterestedMatches = this.remainingInterestedMatches - interestedInExecuted;
    }
  }

  hasRemainingMatches() {
    return this.remainingMatches > 0 ? true : false;
  }

  hasRemainingInterestedMatches() {
    return this.remainingInterestedMatches > 0 ? true : false;
  }

  getUserProfile(): Profile {
    if (this.profile.userOptions && this.profile.oUserOptions === undefined) {
      this.profile.oUserOptions = JSON.parse(this.profile.userOptions);
    } else {
      if (!this.profile.userOptions) {
        // set default options
        this.profile.oUserOptions = new UserOptions(undefined);
        this.profile.userOptions = JSON.stringify(this.profile.oUserOptions);
      }
    }
    return this.profile;
  }

  getUserSubscriptions(): UserSubscriptions {
    if (this.userSubscriptions == undefined) {
      this.userSubscriptions = new UserSubscriptions([]);
    }
    return this.userSubscriptions;
  }

  SaveLikesDislikesToProfile(likes: LikesDislike[], disLikes: LikesDislike[]): void {
    // take likes and dislikes arrays, combine into a single array and searialize
    // to a JSON string.
    var temp = [];
    temp = likes.concat(disLikes);
    if (temp.length > 0) {
      this.profile.likesDislikes = JSON.stringify(temp);
    } else {
      this.profile.likesDislikes = null;
    }
  }

  GetLikes(): LikesDislike[] {
    // desearialize JSON string into an array of LikesDislike object then
    // extract into a likes and dislike arrays.
    if (this.profile.likesDislikes && this.profile.likesDislikes !== "") {
      var temp = <LikesDislike[]>JSON.parse(this.profile.likesDislikes);
      return temp.filter((l: LikesDislike) => l.profileLikeType === ProfileLikeType.Like);
    } else {
      return [];
    }
  }

  GetDislikes(): LikesDislike[] {
    // desearialize JSON string into an array of LikesDislike object then
    // extract into a likes and dislike arrays.
    if (this.profile.likesDislikes && this.profile.likesDislikes !== "") {
      var temp = <LikesDislike[]>JSON.parse(this.profile.likesDislikes);
      return temp.filter((l: LikesDislike) => l.profileLikeType === ProfileLikeType.Dislike);
    } else {
      return [];
    }
  }
}
