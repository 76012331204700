import { Profile } from "./profile";
import { EntityAspect } from "breeze-client";
import { IBreezeExtension } from "common/interfaces";
import { ImageStatus } from "common/enums";

export class ProfileImage implements IBreezeExtension {
  public entityAspect: EntityAspect;
  public profileImageId: string;
  public createdDate: Date;
  public preferred: boolean;
  public displayName: string;
  public fileName: string;
  public fileSize: number;
  public status: number;
  public userId: string;
  public profile: Profile;
  // breeze extensions
  public actionTag: string;

  constructor(profile: Profile, displayName: string, fileName: string, fileSize: number) {
    this.userId = profile.userId;
    this.displayName = displayName;
    this.createdDate = new Date();
    this.preferred = false;
    this.fileName = fileName;
    this.fileSize = fileSize;
    this.status = ImageStatus.NotScanned;
  }

  breezeExtension() {
    this.actionTag = "Click to show";
  }

  breezeInitializer(entity: ProfileImage) {
  }
}