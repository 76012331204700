import { Subscription } from "aurelia-event-aggregator";
import { EventMessageType } from "./enums";

export class EventSubscription {
  eventMessage: EventMessageType;
  eventSubscription: Subscription;
  guid: string;
  constructor(eventMessage: EventMessageType, eventSubscription: Subscription) {
    this.eventMessage = eventMessage;
    this.eventSubscription = eventSubscription;
    this.guid = this.createGuid();
  }

  /**
   * http://byronsalau.com/blog/how-to-create-a-guid-uuid-in-javascript/
   */
  createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}