import { inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Logger } from "services/logger";
import { Utils } from "services/utils";

// import * as routeconfig from "services/route-config";

/** 
 * @module Manage errors in the app
 * @requires logger
 * @requires system
 * @requires utils
 * Date      Who  Change
 * ------    ---   ---------------------------------------------------------
 * 01/07/17  BEM   Port orginal errorhandler.js to Typescript, to allow
 *                 Typescript class to exend this class in lieu of calling
 *                 the orignial includeIn method.
 */
@inject(Utils, Logger, Router)
export class ErrorHandler {
  utils: Utils;
  logger: Logger;
  router: Router;
  targetObject: any;

  // non Typescript modules need a base constructor with
  // with a target argument; whereas, Typescript classes
  // can extend this class.
  constructor(utils: Utils, logger: Logger, router: Router) {
    this.utils = utils;
    this.logger = logger;
    this.router = router;
  }

  /** 
   * Handle WebAPI errors
   * @method
   * @param {object} error - The error object
   */
  handleServerError(err: any, messagePrefix: string): Promise<any> {
    return err.httpResponse.json()
      .then((errorResponse: any) => {
        var message: string;
        if (errorResponse.ExceptionMessage) {
          message = errorResponse.ExceptionMessage;
        } else {
          message = errorResponse.message;
        }
        message = messagePrefix + " - " + message;
        this.logger.logError(message, err, errorResponse, true);
        return errorResponse;
      });
  };

  /**
   * Handle Breeze validation errors
   * @method
   * @param {object} error - The error object
   */
  handleError = (error: any): void => {
    if (error.entityErrors) {
      error.message = this.utils.getSaveValidationErrorMessage(error);
    }

    this.logger.logError(error.message, null, null, true);
    throw error;
  };

  /**
   * Log the error
   * @method
   * @param {string} message
   * @param {bool} showToast - Show a toast using toastr.js
   */
  log = (message: string, showToast: boolean): void => {
    this.logger.logError(message, null, null, showToast);
  };

  /**
   * Handle validation errors without Breeze. 
   * It´s mandatory to return a ModelState object from server.
   * @method
   * @param {jQueryXMLHttpRequest} jqXHR
   * @param {string} textStatus
   * @param {string} error
   */
  handlevalidationerrors = (jqXHR: any, textStatus: string, error: any): void => {
    var data,
      items;

    try {
      data = $.parseJSON(jqXHR.responseText);
    } catch (e) {
      data = null;
    }

    if (!data || !data.message) {
      return;
    }

    if (data.modelState) {
      for (var key in data.modelState) {
        if (data.modelState.hasOwnProperty(key)) {
          items = data.modelState[key];

          if (items.length) {
            for (var i = 0; i < items.length; i++) {
              this.logger.logError(items[i], null, items, true);
            }
          }
        }
      }
    } else {
      this.logger.logError(data.message, null, data, true);
    }
  };

  /**
   * Handle authentication errors                 
   * @method
   * @param {object} errors
   * @returns {object} error object
   */
  handleauthenticationerrors = (errors: any): void => {
    var error = {
      message: "Invalid user and/or password. Please try again.",
      title: "Authentication/Login",
      status: ""
    }
    this.logger.logError(error.message, errors, error.title, true);
  };

  /**
   * Handle authentication errors                 
   * @method
   * @param {object} response
   * @returns void
   */
  handlehttpstatus = (response: any): void => {
    switch (<HTTPStatusCode>response.status) {
      case HTTPStatusCode.Accepted:
        break;
      case HTTPStatusCode.Accepted:
        break;
      case HTTPStatusCode.BadGateway:
        break;
      case HTTPStatusCode.BadRequest:
        break;
      case HTTPStatusCode.Conflict:
        break;
      case HTTPStatusCode.Continue:
        break;
      case HTTPStatusCode.Created:
        break;
      case HTTPStatusCode.ExpectationFailed:
        break;
      case HTTPStatusCode.Forbidden:
        break;
      case HTTPStatusCode.Found:
        break;
      case HTTPStatusCode.GatewayTimeout:
        break;
      case HTTPStatusCode.Gone:
        break;
      case HTTPStatusCode.HttpVersionNotSupported:
        break;
      case HTTPStatusCode.InternalServerError:
        break;
      case HTTPStatusCode.LengthRequired:
        break;
      case HTTPStatusCode.MethodNotAllowed:
        break;
      case HTTPStatusCode.MovedPermanently:
        break;
      case HTTPStatusCode.MultipleChoices:
        break;
      case HTTPStatusCode.NoContent:
        break;
      case HTTPStatusCode.NonAuthoritativeInformation:
        break;
      case HTTPStatusCode.NotAcceptable:
        break;
      case HTTPStatusCode.NotFound:
        break;
      case HTTPStatusCode.NotImplemented:
        break;
      case HTTPStatusCode.NotModified:
        break;
      case HTTPStatusCode.OK:
        break;
      case HTTPStatusCode.PartialContent:
        break;
      case HTTPStatusCode.PaymentRequired:
        break;
      case HTTPStatusCode.PreconditionFailed:
        break;
      case HTTPStatusCode.ProxyAuthenticationRequired:
        break;
      case HTTPStatusCode.RequestedRangeNotSatisfiable:
        break;
      case HTTPStatusCode.RequestEntityTooLarge:
        break;
      case HTTPStatusCode.RequestTimeout:
        break;
      case HTTPStatusCode.RequestUriTooLong:
        break;
      case HTTPStatusCode.ResetContent:
        break;
      case HTTPStatusCode.SeeOther:
        break;
      case HTTPStatusCode.ServiceUnavailable:
        break;
      case HTTPStatusCode.SwitchingProtocols:
        break;
      case HTTPStatusCode.TemporaryRedirect:
        break;
      case HTTPStatusCode.TooManyRequests:
        break;
      case HTTPStatusCode.Unauthorized:
        break;
      case HTTPStatusCode.UnprocessableEntity:
        break;
      case HTTPStatusCode.UnsupportedMediaType:
        break;
      case HTTPStatusCode.UseProxy:
        break;
    }
  };


};

