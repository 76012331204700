export class SessionState {
  roleList: string;
  isEmailConfirmed: boolean;
  rememberMe: boolean;
  userId: string;
  externalId: string;
  userName: string;
  userAvatar: string;
  constructor() {
    this.roleList = "";
    this.isEmailConfirmed = false;
    this.rememberMe = true;
    this.userId = "";
    this.userName = "";
    this.userAvatar = "";
    this.externalId = "";
  }
}