import { MockTestMode, PaymentProcessor, APITestMode } from "../../src/common/enums";

export class ConfigurationSetting {
  public readonly breezeServiceName: string;
  public readonly baseApiUrl: string;
  public readonly clientUrl: string;
  public readonly hubUrl: string;
  public readonly fileUploadDir: string;
  public readonly imageBaseDir: string;
  public readonly byPassSendingEmail: boolean;
  public readonly clientDomain: string;
  public readonly onlyShowSpotlight: boolean;
  public appInitialized: boolean;
  public readonly packageName: string;
  public readonly mockServiceTest: MockTestMode;
  public readonly paymentProcessor: PaymentProcessor;
  public readonly apiTestMode: APITestMode;

  constructor() {
    this.clientDomain = "vstage.spotonlove";
    this.clientUrl = `https://${this.clientDomain}.com/`;
    this.baseApiUrl = `https://api${this.clientDomain}.com/`;  //  spotonlove.WebApi Project - GoDaddy; 
    this.breezeServiceName = "breeze";
    this.fileUploadDir = "/img/user/";
    this.hubUrl = `https://api${this.clientDomain}.com/signalr`;
    this.imageBaseDir = "/img/user/";
    // configuration setting when enable allow by passing email functionality
    // and display information that would have been received via email.
    // for this to work the WebApi project need to be compiled with the 
    // NOEMAIL preprocessor directive.
    this.byPassSendingEmail = false;
    this.onlyShowSpotlight = false;
    this.appInitialized = false;
    this.packageName = "com.spotonlove.vstage.twa";
    // Enable mock service testing and payment processor which is useful for Google Play billing.
    this.mockServiceTest = MockTestMode.None;
    this.paymentProcessor = PaymentProcessor.InstallationDefault;
    this.apiTestMode = APITestMode.None;
  }
}
