export class RouteModelBase {
  /**
   * Assign JSON data
   * param {object} JSON data to assign
   */
  assignJSON(json: object) {
    for (var key in json) {
      if (this.hasOwnProperty(key)) {
        this[key] = json[key];
      }
    }
  }
}